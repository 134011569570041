import Navbar from "../Navbar"
import React from "react"
import Footer from "../Footer"
import { Avatar, Pagination, Skeleton } from "antd"
import ReviewItem, { Review } from "./ReviewItem"
import useReviews from "../../hooks/useReviews"
import { useSearchParams } from "react-router-dom"
import NotFoundPlaceholder from "../NotFoundPlaceholder"
import { UserOutlined } from "@ant-design/icons"
import { getBase64FromBuffer } from "../../utils/helpers"
import { useTranslation } from "react-i18next"

const showLoadingSkeleton = (
    <div>
        {[...Array(5)].map((_, i) => (
            <div key={i}
                 className={`flex items-center p-4 border-b-[1px] ${i === 0 ? "border-t-[1px]" : ""} border-lightgray`}>
                <div className="mr-5">
                    <Skeleton.Image style={{ width: 100, height: 100 }} />
                </div>
                <div className="flex-grow min-w-[300px]">
                    <Skeleton active />
                </div>
            </div>
        ))}
    </div>
)

const Reviews = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const page = searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1

    const { data: reviewSearch, isLoading, isError } = useReviews({
        userid: searchParams.get("userId"),
        page: searchParams.get("page"),
    })
    const reviews = reviewSearch?.reviews

    let userProfilePic
    if (reviewSearch?.userAvatar) {
        userProfilePic = getBase64FromBuffer(reviewSearch?.userAvatar.data)
    }

    const setPage = (pageNumber: number) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("page", pageNumber.toString())
        setSearchParams(newSearchParams)
    }

    const showUserReviewInfo = (
        <>
            <div className={"px-3 pb-4 mx-3 flex justify-center"}>
                <Avatar className={"flex-none"} size={100} icon={
                    userProfilePic
                        ?
                        <img src={userProfilePic}
                             alt="avatar"
                             className={"object-cover w-32 h-32 rounded-full"}>
                        </img>
                        :
                        <UserOutlined />
                } />
            </div>
            {(reviewSearch?.reviews && reviewSearch?.reviews.length > 0) ?
                <div className={"px-3 pb-3 mx-3 mb-5 flex justify-center border-b-[1px] border-lightgray"}>
                    <div>{reviewSearch?.reviewsCount === 0 || !reviewSearch?.positiveReviewRatio
                        ?
                        t("label.noReviewsYet")
                        :
                        reviewSearch?.reviewsCount + " " + t("label.reviews") + " (" + reviewSearch?.positiveReviewRatio * 100 + "% " + t("label.positive") + ")"}</div>
                </div>
                :
                <div className={"mt-3 mb-5 flex border-b-[1px] border-lightgray"}></div>
            }
        </>
    )

    return (
        <div>
            <Navbar />
            <div className="flex justify-center">
                <div className={"max-w-3xl grow"}>
                    <h1 className={"text-center px-6 pt-6 pb-3 text-2xl"}>
                        {t("label.reviewsBig")}
                        {reviewSearch?.ratedUserFirstName &&
                            " " + t("label.for") + " " + reviewSearch?.ratedUserFirstName
                        }
                    </h1>
                    {isError
                        ?
                        <div className={"grid grid-cols-1 gap-4"}>
                            {t("notification.error.reviews")}
                        </div>
                        :
                        <div>
                            {isLoading ?
                                showLoadingSkeleton
                                :
                                <>
                                    {showUserReviewInfo}
                                    {reviews?.length == 0 &&
                                        <div className={"flex justify-center mb-5"}>
                                            <NotFoundPlaceholder text={"No reviews found."} />
                                        </div>
                                    }
                                    < div className={"grid grid-cols-1 gap-4"}>
                                        {reviews && reviews.map((value: Review) =>
                                            <ReviewItem reviewData={value} />,
                                        )}
                                    </div>
                                    <div className={"flex justify-center mt-4"}>
                                        <Pagination
                                            total={reviewSearch?.pageCount ? reviewSearch.pageCount * 10 : 1}
                                            defaultCurrent={page}
                                            onChange={(page) => setPage(page)}
                                            showSizeChanger={false} />
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Reviews