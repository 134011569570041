import { useMutation, useQueryClient } from "react-query"
import axios from "axios"
import { queryKey } from "../utils/consts"
import { Item } from "./useItems"
import useNotification from "./useNotification";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface ItemMutationData {
    itemData: Partial<Item>
    itemId: string
}
interface ItemAdminMutationData {
    itemData: any
    itemId: string
}
interface ItemDeleteData {
    itemId: string
}



const useItemMutations = () => {
    const queryClient = useQueryClient()
    const notifications = useNotification()
    const navigator = useNavigate()
    const {t} = useTranslation()

    const invalidateItem = () => {
        queryClient.invalidateQueries([queryKey.items])
        queryClient.invalidateQueries([queryKey.flaggedItems])
    }

    const itemCreation = useMutation((itemData:Partial<Item>) => axios.post('/item/create', itemData), {
        onError: () => {
            notifications("error", t("notification.error.createItem.title"), t("notification.error.createItem.message") )
        },
        onSuccess: () =>{
            notifications("success", t("notification.success.createItem.title"), t("notification.success.createItem.message"))
            navigator("/my-items")
        }
    })
    const itemMutation = useMutation(({ itemData, itemId }: ItemMutationData) => axios.put(`/item/${itemId}`, itemData), {
        onError: () => {
            notifications("error", t("notification.error.updateItem.title"), t("notification.error.updateItem.message") )
        },
        onSuccess: () =>{
            notifications("success", t("notification.success.updateItem.title"), t("notification.success.updateItem.message"))
        }
    })
    const itemAdminMutation = useMutation(({ itemData, itemId }: ItemAdminMutationData) => axios.put(`/item-admin/${itemId}`, itemData), {
        onError: () => {
            notifications("error", t("Item update did not work"), t("Please try later again") )
        },
        onSuccess: () =>{
            notifications("success", t("Item is changed"), t("The item is now updated"))
        }
    })
    const itemDeletion = useMutation(({ itemId }: ItemDeleteData) => axios.delete(`/item/${itemId}`), {onSuccess: invalidateItem})
    const itemPhotoCreation = useMutation((formData:FormData) => axios.post(`/item/photo`, formData))
    return {itemCreation, itemAdminMutation, itemDeletion, itemMutation, itemPhotoCreation}
}

export default useItemMutations