import React, { FC } from "react"
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

export interface Review {
    _id: string;
    ratingUserId: string;
    ratingUserFirstName: string;
    ratedUserIsLender: boolean;
    ratedUserId: string;
    ratingDate: Date;
    ratedItemId: string;
    ratedItemName: string;
    positiveRating: boolean;
    description: string;
}

interface ReviewInterface {
    reviewData: Review
}

const ReviewItem: FC<ReviewInterface> = ({ reviewData }) => {

    const reviewDate: Date = new Date(reviewData.ratingDate)
    const { t } = useTranslation()

    function getDateLabel(reviewDate: Date): string {
        const now = new Date()
        const nowDay = Math.floor(now.getTime() / 8.64e7)
        const reviewDay = Math.floor(reviewDate.getTime() / 8.64e7)
        const daysDifference = nowDay - reviewDay

        if (daysDifference == 0) {
            return t("label.today")
        } else if (daysDifference == 1) {
            return daysDifference + " " + t("label.dayAgo")
        } else if (daysDifference > 1 && daysDifference < 365) {
            return daysDifference + " "+ t("label.daysAgo")
        } else {
            return t("label.moreThanYear")
        }

    }

    const dateLabel: string = getDateLabel(reviewDate)

    return (
        <div className={"px-3 pb-3 mx-3 border-b-[1px] border-lightgray"}>
            <div className={"flex items-center justify-start"}>
                {reviewData.positiveRating
                    ?
                    <PlusCircleFilled className={"text-xl text-primary"} />
                    :
                    <MinusCircleFilled className={"text-xl text-red-600"} />
                }
                <div className={"mx-2"}>{reviewData.ratingUserFirstName} </div>
                <div className={"text-xl"}>•</div>
                <div className={"mx-2"}>{dateLabel}</div>
            </div>
            <div>{reviewData.ratedUserIsLender}</div>
            <div className={"my-2 "}>
                <u>{t("label.for")} {reviewData.ratedUserIsLender
                    ?
                    t("label.lending")+" "
                    :
                    t("label.borrowing")+" "}
                    '{reviewData.ratedItemName}'</u></div>
            <div className={"text-sm"}>{reviewData.description}</div>
        </div>
    )
}

export default ReviewItem