import { Button } from "antd"
import bgImg from "../../assets/bgImg.jpg"
import { useTranslation } from "react-i18next"
import React from "react"
import ItemCards from "./ItemCards"
import MainLayout from "../MainLayout"
import { Link } from "react-router-dom"


const HomePage = () => {
    const { t } = useTranslation()

    return (
        <MainLayout>
            <main className="relative flex-1 flex flex-col md:mt-0">
                <div className="relative flex-grow sm:min-h-[55vh] min-h-[30vh]">
                    <div
                        className={"absolute h-full top-0 left-0 right-0 bottom-0 bg-no-repeat bg-cover box-border inline-block overflow-hidden"}>
                        <img src={bgImg} className="w-full pointer-events-none object-cover" alt="Carousel Item" />
                    </div>
                    <div className={"relative hidden md:flex justify-center items-center h-full p-3"}>
                        <div className="z-20 w-1/3 flex-col">
                            <div className={"bg-white text-cgrey w-[22rem] p-8 shadow"}>
                                <h2 style={{ textShadow: `0 0 2px #FFFFFF` }} className={`text-4xl mb-4 text-cgrey`}>
                                    {t("label.homepageCaption")}
                                </h2>
                                <h2 style={{ textShadow: `0 0 2px #FFFFFF` }} className={`text-sm mb-4 text-cgrey`}>
                                    {t("label.homepageSubCaption")}
                                </h2>
                                <Link to={"/offer-item"}>
                                    <Button size={"large"} type={"primary"}
                                            className="text-white bg-primary w-full">{t("action.offer-item")}</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"block md:hidden text-center m-4"}>
                    <h2 style={{ textShadow: `0 0 2px #FFFFFF` }} className={`text-3xl mb-8`}>
                        {t("label.homepageCaption")}
                    </h2>
                    <Link to={"offer-item"}>
                        <Button size={"large"} type={"primary"}
                                className="text-white bg-primary w-full">{t("action.offer-item")}</Button>
                    </Link>
                </div>
                <ItemCards />
            </main>
        </MainLayout>
    )
}

export default HomePage
