import React, { FC, useState } from "react"
import { Button, DatePicker, DatePickerProps, Form } from "antd"
import { useTranslation } from "react-i18next"
import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import axios from "axios"
import useNotification from "../../../hooks/useNotification"
import { queryKey } from "../../../utils/consts"
import { queryClient } from "../../../App"
import dayjs, { Dayjs } from "dayjs"
import { getAge } from "../../../utils/helpers"

interface DateOfBirth {
    dateOfBirth: Date
}

const DateOfBirth: FC<DateOfBirth> = ({ dateOfBirth }) => {
    const { t } = useTranslation()

    const [edit, setEdit] = useState<boolean>(false)
    const [dateOfBirthInput, setDateOfBirthInput] =
        useState<Dayjs | undefined>(dayjs(new Date(dateOfBirth)))

    const notification = useNotification()

    const addDateOfBirth = useMutation([queryKey.user],
        (dateOfBirth: Date) => {
            return axios.post("/auth/changeDateOfBirth", { dateOfBirth: dateOfBirth })
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKey.user])
                setEdit(false)
            },
            onError: () => notification("error", "Failed changing date of birth", ""),
        },
    )

    const onFinish = () => {
        if (dateOfBirthInput && getAge(dateOfBirthInput?.toDate()) < 18) {
            notification("error", t("notification.error.signup.under-eighteen.title"), t("notification.error.signup.under-eighteen.message"))
        } else if (dateOfBirthInput) {
            addDateOfBirth.mutate(dateOfBirthInput.toDate())
        }
    }

    const onChangeBirthDate: DatePickerProps["onChange"] = (date) => {
        if (date) {
            setDateOfBirthInput(date)
        } else {
            setDateOfBirthInput(undefined)
        }
    }

    return (
        <div className={"flex justify-between"}>
            <div className={"flex"}>
                {edit ?
                    <Form
                        onFinish={onFinish}
                    >
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name="lastName"
                            rules={[{ required: true, message: "Please input your date of birth!" }]}
                            className={"mb-4"}
                        >
                            <DatePicker
                                size={"small"} className={"w-full"}
                                format={"DD.MM.YYYY"}
                                defaultValue={dateOfBirthInput}
                                onChange={onChangeBirthDate} />
                        </Form.Item>
                    </Form>
                    :
                    <div className={"flex pt-1 pl-2 align-middle"}>
                        {dayjs(dateOfBirth.toString()).format("DD.MM.YYYY")}
                    </div>
                }
            </div>
            <div>
                {edit ?
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => onFinish()}
                            icon={<CheckOutlined />}
                            title={t("action.submit") ?? ""} />
                    :
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => setEdit(true)}
                            icon={<EditOutlined />}
                            title={t("action.edit") ?? ""} />}
            </div>
        </div>
    )
}

export default DateOfBirth