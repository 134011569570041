import React from "react"
import { Pagination, Skeleton } from "antd"
import BrowseItem from "./BrowseItem"
import Filter from "./Filter"
import { useSearchParams } from "react-router-dom"
import useItems from "../../hooks/useItems"
import MainLayout from "../MainLayout"
import SortBy from "./SortBy"
import NotFoundPlaceholder from "../NotFoundPlaceholder"
import { useTranslation } from "react-i18next"


const showLoadingSkeleton = (
    <div>
        {[...Array(3)].map((_, i) => (
            <div key={i}
                 className={`flex items-center p-4 border-b-[1px] ${i === 0 ? "border-t-[1px]" : ""} border-lightgray`}>
                <div className="mr-5">
                    <Skeleton.Image style={{ width: 100, height: 100 }} />
                </div>
                <div className="flex-grow">
                    <Skeleton active />
                </div>
            </div>
        ))}
    </div>
)

const Browse = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const category = searchParams.get("category")
    const search = searchParams.get("search")
    const maxPrice = searchParams.get("maxPrice") ? parseInt(searchParams.get("maxPrice")!) : null
    const address = searchParams.get("address")
    const limit = searchParams.get("limit") ? parseInt(searchParams.get("limit")!) : null
    const page = searchParams.get("page") ? parseInt(searchParams.get("page")!) : 1
    const distance = searchParams.get("distance") ? parseInt(searchParams.get("distance")!) : null
    const minPrice = searchParams.get("minPrice") ? parseInt(searchParams.get("minPrice")!) : null
    const minimal_renting = searchParams.get("minRentingPeriod") ? parseInt(searchParams.get("minRentingPeriod")!) : null
    const availability_start = searchParams.get("availability_start")
    const availability_end = searchParams.get("availability_end")
    const sortBy = searchParams.get("sortBy")
    const { t } = useTranslation()

    const setPage = (pageNumber: number) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("page", pageNumber.toString())
        setSearchParams(newSearchParams)
    }

    const { data: itemsSearch, isLoading } = useItems({
        category,
        search,
        maxPrice,
        address,
        limit,
        page,
        distance,
        minPrice,
        minimal_renting,
        availability_start,
        availability_end,
        sortBy,
    })

    return (
        <MainLayout>
            <details className={"p-3 md:hidden"}>
                <summary className="font-semibold mb-2">
                    Filters
                </summary>
                <div className={"flex-none"}>
                    <Filter />
                </div>
            </details>
            <div className={"md:flex"}>
                <div className={"hidden md:block md:w-72 md:border-r-[1px] md:border-lightgray"}>
                    <Filter />
                </div>
                <div className={"grow"}>
                    <div className={"flex justify-between"}>
                        <h1 className={"p-4 text-2xl"}>
                            {t("label.results")} {category && `${t("label.inCategory")} ${t(`categories.${category}`)}`}
                        </h1>
                        <div className={"flex items-center pr-5"}>
                            <SortBy />
                        </div>
                    </div>
                    {isLoading ?
                        showLoadingSkeleton
                        :
                        <>
                            <div className={"mx-4"}>
                                {itemsSearch?.items?.map((item, cnt) => <BrowseItem itemData={item} key={cnt} />)}
                            </div>
                            <div className={"flex justify-center mt-4"}>
                                {itemsSearch?.items.length == 0 ?
                                <NotFoundPlaceholder text={"No items found."}/>
                                    :
                                <Pagination
                                    total={itemsSearch?.pageCount ? itemsSearch.pageCount * 10: 1}
                                    defaultCurrent={page}
                                    onChange={(page) => setPage(page)}
                                    showSizeChanger={false}/>}
                            </div>
                        </>
                    }
                </div>
            </div>
        </MainLayout>
    )
}

export default Browse
