import { useQuery } from "react-query"
import { queryKey } from "../utils/consts"
import axios from "axios"
import { Review } from "../components/Reviews/ReviewItem"

interface SearchParams {
    userid: string | null;
    page: string | null;
}

export interface ReviewSearch {
    reviewsCount: number;
    pageCount: number;
    currentPage: number;
    reviews: Review[]
    positiveReviewRatio: number;
    ratedUserFirstName: string;
    userAvatar: {
        type: string
        data: Buffer
    };
}

const useReviews = (filter: SearchParams) => {
    return useQuery([queryKey.reviews, filter.page, filter.userid], async (): Promise<ReviewSearch> => {
            if (filter.userid && filter.page) {
                const res = await axios.get(`/review?userid=${filter.userid}&page=${filter.page}`)
                return res.data
            } else if (filter.userid) {
                const res = await axios.get(`/review?userid=${filter.userid}`)
                return res.data
            } else {
                const res = await axios.get(`/review`)
                return res.data
            }
        },
        { retry: false })

}

export default useReviews