import { useMutation, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"
import { queryKey } from "../../utils/consts"
import axios from "axios"

async function deleteAvatar(): Promise<any> {
    return await axios.post('/profile/deleteAvatar',
        {}, {})
        .catch(reason => {
            throw reason.response.data.error
        }
    )
}

export function useDeleteAvatar() {
    const queryClient = useQueryClient()
    const notification = useNotification()
    const { t } = useTranslation()

    return useMutation(
        () => deleteAvatar(), {
            onSuccess: (data:any) => {
                queryClient.setQueryData([queryKey.deleteAvatar], data);
                notification("success", t("notification.success.deletePic.title"), t("notification.success.deletePic.message"))
            },
            onError: () => {
                notification("error", t("notification.error.deletePic.title"), t("notification.error.deletePic.message"))
            }
        })
}

export default useDeleteAvatar