import React from "react"
import { Divider, Skeleton, Tabs, TabsProps } from "antd"
import "./MyItems.css"
import ItemComponent from "./ItemComponent"
import MainLayout from "../MainLayout"
import useMyItems from "../../hooks/useMyItems"
import { useUser } from "../../hooks/auth/useUser"
import { useTranslation } from "react-i18next"
import { getItemStatus } from "../../utils/helpers"
import NotFoundPlaceholder from "../NotFoundPlaceholder"
import { Item } from "../../hooks/useItems"


const showLoadingSkeleton = (
    <div>
        {[...Array(3)].map((_, i) => (
            <div key={i}
                 className={`flex items-center p-4 border-b-[1px] ${i === 0 ? "border-t-[1px]" : ""} border-lightgray`}>
                <div className="mr-5">
                    <Skeleton.Image style={{ width: 100, height: 100 }} />
                </div>
                <div className="flex-grow">
                    <Skeleton active />
                </div>
            </div>
        ))}
    </div>
)


const MyItems = () => {
    const { t } = useTranslation()

    const { data: myItems, isLoading } = useMyItems()
    const { user } = useUser()

    const renderTabs = (item: Item, cnt: number) => {
        if (cnt === 0) return <ItemComponent key={cnt} itemData={item} defaultOpened isLender={item.lender._id === user?._id} />
        return <>
            <Divider className={"!p-0 !m-0"}/>
            <ItemComponent itemData={item} key={cnt} isLender={item.lender._id === user?._id}/>
        </>
    }

    let currentTab = myItems?.filter(item => getItemStatus(item) !== "returned").map(renderTabs)
    let historyTab = myItems?.filter(item => getItemStatus(item) === "returned").map(renderTabs)


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t("label.myItem.current"),
            children: currentTab?.length !== 0 ? currentTab : <NotFoundPlaceholder text={t("label.myItem.noActiveProcesses")}/>,
        },
        {
            key: '2',
            label: t("label.myItem.history"),
            children: historyTab?.length !== 0 ? historyTab : <NotFoundPlaceholder text={t("label.myItem.noHistory")}/>,
        },
    ];

    return (
        <MainLayout>
            <div className={"px-4"}>
                {isLoading ?
                    showLoadingSkeleton
                    :
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                    />
                }
            </div>
        </MainLayout>
    )
}

export default MyItems