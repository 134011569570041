import axios, { AxiosError, AxiosResponse } from "axios"
import { AuthErrorTypes } from "./utils/consts"
import useNotification from "./hooks/useNotification"
import i18n from "i18next"

axios.defaults.baseURL = "/api"
axios.defaults.withCredentials = true

interface AuthErrorResponse {
    ok: boolean,
    status: number,
    authErrorType: AuthErrorTypes
}

axios.interceptors.response.use(
    (response: AxiosResponse) => {
        // If the response was successful, there's no need to do anything
        return response;
    },
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            const err = error as AxiosError<AuthErrorResponse>
            const notification = useNotification()
            if (err?.response?.data.authErrorType === AuthErrorTypes.ExpiredAuthToken) {
                notification("warning", i18n.t("notification.warning.expired-auth-token.title"), i18n.t("notification.warning.expired-auth-token.message"))
            } else if (err?.response?.data.authErrorType === AuthErrorTypes.BlockedAccount) {
                notification("error", i18n.t("notification.error.blocked-account.title"), i18n.t("notification.error.blocked-account.message"))
            }
        }

        // If the error status is not 401, we don't want to do anything special
        return Promise.reject(error);
    }
);