import { useMutation, UseMutationResult } from "react-query"
import { useNavigate } from "react-router-dom"
import { User } from "./useUser"
import useNotification from "../useNotification"
import { useTranslation } from "react-i18next"
import axios from "axios"


async function signIn(email: string, password: string): Promise<User> {
    return await axios.post('/auth/signin',
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
    )
}

type IUseSignIn = UseMutationResult<User, unknown, {email: string, password: string}, unknown>

export function useSignIn(redirectPath: string): IUseSignIn {
    const navigate = useNavigate();
    const notification = useNotification()
    const { t } = useTranslation()

    return useMutation<User, unknown, { email: string, password: string }, unknown>(
        ({
             email,
             password
         }) => signIn(email, password), {
            onSuccess: () => {
                navigate(redirectPath)
            },
            onError: () => {
                notification("error", t("notification.error.signin.title"), t("notification.error.signin.message"))
            }
        })
}