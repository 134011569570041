import { Button, Modal } from "antd"
import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import TextArea from "antd/lib/input/TextArea"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Item } from "../../hooks/useItems"
import { useMutation, useQueryClient } from "react-query"
import { useUser } from "../../hooks/auth/useUser"
import axios from "axios"
import useNotification from "../../hooks/useNotification"
import { queryKey } from "../../utils/consts"

interface ReportIncidentModalProps {
    open: boolean
    item: Item
    isLender: boolean
    onCancel(): void
}


const ReportIncidentModal: FC<ReportIncidentModalProps> = ({ open, item, onCancel, isLender }) => {
    const { t } = useTranslation()
    const notification = useNotification()
    const queryClient = useQueryClient()

    const [incidentReason, setIncidentReason] = useState("")
    const incident = useMutation((itemData: Partial<Item>) => axios.post('/item/flagged', itemData), {
        onError: () => {
            notification("error", t("notification.error.general.title"), t("notification.error.general.message") )
        },
        onSuccess: () => {
            setIncidentReason("")
            notification("success", "", t("notification.success.incident"))
            close()
            queryClient.invalidateQueries([queryKey.myItems])
        }
    })
    const { user } = useUser()

    const close = () => {
        setIncidentReason("")
        onCancel()
    }

    const handleReportIncident = () => {
        if (isLender) {
            incident.mutate({ _id: item._id, flaggedLender: user?._id, flaggedLenderDescription: incidentReason })
        } else {
            incident.mutate({ _id: item._id, flaggedBorrower: user?._id, flaggedBorrowerDescription: incidentReason })
        }
    }

    return (
        <Modal
            title={t("action.reportIncident")}
            open={open}
            onCancel={() => close()}
            footer={[
                <Button key="back" onClick={() => onCancel()}>
                    {t("action.cancel")}
                </Button>,
                <Button type={"primary"} className="text-white font-semibold bg-primary"
                        onClick={() => handleReportIncident()}
                        icon={<ExclamationCircleOutlined />}>{t("action.reportIncident")}</Button>,
            ]}
        >
            <TextArea
                className={"mb-10 mt-5"}
                value={incidentReason}
                showCount
                style={{ resize: "none" }}
                maxLength={1000}
                rows={6}
                onChange={e => setIncidentReason(e.target.value)}
                placeholder={t("placeholder.reportIncident") ?? ""}
            />
        </Modal>
    )
}

export default ReportIncidentModal