import React from "react"
import { useSearchParams } from "react-router-dom"
import { Tag } from 'antd';
import { CloseOutlined } from "@ant-design/icons"
import { useCategories } from "../../../hooks/useCategories"
import { useTranslation } from "react-i18next"

const CategoriesFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedCategory = searchParams.get("category")
    const { data: categories } = useCategories()
    const { t } = useTranslation()

    const addCategory = (category: string) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("category",category)
        newSearchParams.set("page","1")
        setSearchParams(newSearchParams)
    }

    const removeCategory = () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete("category")
        setSearchParams(newSearchParams)
    };

    return (
        <details open>
            <summary className="font-semibold mb-2">
                {t("label.categories")}
            </summary>
            {selectedCategory
                ? <div>
                    <Tag className="flex justify-between w-full py-0.5" onClick={removeCategory}>
                        <div className="text-darkgray">
                            {t(`categories.${selectedCategory}`)}
                        </div>
                        <CloseOutlined />
                    </Tag>
                </div>
                : <div>
                    {categories?.map(cat => (
                        <div onClick={() => addCategory(cat)}
                              className="block py-0.5 text-sm pl-1 hover:bg-gray-100 whitespace-nowrap">
                            {t(`categories.${cat}`)}
                        </div>
                    ))}
                </div>
            }
        </details>
    )
}

export default CategoriesFilter
