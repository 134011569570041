import logo from "../../assets/logo/logo.svg"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Checkbox, Form, Input } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSignIn } from "../../hooks/auth/useSignIn"
import { useUser } from "../../hooks/auth/useUser"

const Login = () => {
    const { search } = useLocation()
    const parameters = new URLSearchParams(search)
    const redirectPath = parameters.get("redirect") ?? "/"


    const { t } = useTranslation()
    const [form] = Form.useForm()
    const signInUser = useSignIn(redirectPath)
    const { isAuth } = useUser()
    const navigate = useNavigate()

    const [emailInput, setEmailInput] = useState<string>("")
    const [passwordInput, setPasswordInput] = useState<string>("")


    useEffect(() => {
        if (isAuth) {
            navigate("/")
        }
    }, [isAuth])

    const onFinish = () => {
        signInUser.mutate({ email: emailInput, password: passwordInput })
    }


    return (
        <>
            <div className={"flex items-center justify-center flex-col my-16"}>
                <div className={"flex flex-col gap-8 items-center"}>
                    <Link to={"/"}><img src={logo} className="w-56 pl-1" alt="logo" /></Link>
                    <Form
                        name="normal_login"
                        className="w-80"
                        initialValues={{ remember: true }}
                        layout={"vertical"}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item
                            name="mail"
                            label={t("label.mail")}
                            rules={[{
                                required: true,
                                message: t("notification.error.required", { field: t("label.mail") }) as string,
                            }]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"}
                                   placeholder={t("label.mail") as string}
                                   type={"email"}
                                   value={emailInput}
                                   onChange={e => setEmailInput(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label={t("label.password")}
                            rules={[{
                                required: true,
                                message: t("notification.error.required", { field: t("label.password") }) as string,
                            }]}
                        >
                            <Input size={"large"} className={"rounded"} placeholder={t("label.password") as string}
                                   type={"password"}
                                   value={emailInput}
                                   onChange={e => setPasswordInput(e.target.value)} />
                        </Form.Item>
                        <Form.Item>
                            <div className={"flex justify-between"}>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>{t("label.remember-me")}</Checkbox>
                                </Form.Item>
                                <Link to={"/password-forget"} className={"hover:text-primary"}>
                                    {t("action.forgot-password")}
                                </Link>
                            </div>
                        </Form.Item>
                        <Form.Item className={"mb-4"}>
                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                                className={"bg-primary text-white !rounded-3xl w-full"}
                                size={"large"}
                                loading={signInUser.isLoading}
                                disabled={!emailInput || !passwordInput}
                            >
                                {t("action.login")}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Link to={"/sign-up"}>
                                <Button
                                    className={"!rounded-3xl w-full"}
                                    size={"large"}
                                >
                                    {t("action.signup")}
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Login