import { useUser } from "../../hooks/auth/useUser"
import React from "react"
import { Button } from "antd"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const UnverifiedNote = () => {
    const { mailVerified, idVerified, isAuth } = useUser()
    const { t } = useTranslation()

    const ButtonToProfile = (
            <Button className="bg-primary border-cgrey justify-self-center">
                <Link to={"/profile/verification"}>{t("action.toProfile")}</Link>
            </Button>
    )

    return (
        <div>
            {(mailVerified !== "verified" || idVerified !== "verified") && isAuth &&
                <nav>
                    {/* mobile */}
                    <div
                        className="md:hidden grid grid-cols-1 p-2 text-sm font-semibold relative z-30 top-24 w-full bg-primary">
                        <span className="text-grey p-2 justify-self-center">{t("notification.error.notVerified")}</span>
                        {ButtonToProfile}
                    </div>
                    {/* desktop */}
                    <div className="hidden md:flex items-center justify-between p-2 text-sm font-semibold bg-primary"
                         role="alert">
                        <span className="text-grey">{t("notification.error.notVerified")}</span>
                        {ButtonToProfile}
                    </div>
                </nav>}
        </div>
    )
}

export default UnverifiedNote