import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import React, { useState } from "react"
import { Button } from "antd"
import MainLayout from "../MainLayout"
import BorrowItem from "../BorrowItem"
import useSingleItem from "../../hooks/useSingleItem"
import { formatCurrency, toUTCDate } from "../../utils/helpers"
import noImage from "../../assets/no-image.svg"
import { useUser } from "../../hooks/auth/useUser"
import useNotification from "../../hooks/useNotification"
import { useTranslation } from "react-i18next"

const ItemDetails = () => {
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()
    const notification = useNotification()
    const itemId = searchParams.get("itemId")
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { t } = useTranslation()
    const { idVerified, mailVerified, isAuth } = useUser()
    const item = useSingleItem(itemId ?? "")

    const stringWithDayOrDays = (minimalRentingPeriod: number): string => {
        if (minimalRentingPeriod == 1) {
            return minimalRentingPeriod + " "+t("label.day")
        } else {
            return minimalRentingPeriod + " "+t("label.days")
        }
    }

    const renderImage = (
        <div className={"w-80 h-80 flex-none overflow-hidden relative"}>
            <img alt={"item"} src={
                item?.data?.photo ? `api/item/photo/${item?.data?.photo}` : noImage}
                 className="w-full h-full object-contain border-[1px] border-lightgray" />
        </div>
    )

    const handleRentNow = () => {
        if (!isAuth) {
            navigate(`/login?redirect=${location.pathname + location.search}`)
        } else if (mailVerified !== "verified" || idVerified !== "verified") {
            notification("error", t("notification.error.verificationNeeded.title"), t("notification.error.verificationNeeded.message"))
            navigate("/profile/verification")
        } else {
            setIsModalOpen(true)
        }
    }

    return (
        <MainLayout>
            <div className="flex flex-col md:justify-center pt-8 px-8 mx-auto">
                <div className={"flex flex-col md:flex-row"}>
                    <div className={"flex m-3 justify-center md:mr-8"}>
                        {renderImage}
                    </div>
                    <div className={"p-4 grow max-w-2xl"}>
                        <h1 className={"font-semibold text-center md:text-left mb-3 text-2xl"}>
                            {item.data?.name}
                        </h1>
                        <div className={"flex justify-between pb-3 border-b-[1px] border-lightgray"}>
                            <div>{t("label.by")} {item.data?.lender?.firstName}</div>
                            <Link
                                to={"/reviews?userId=" + item.data?.lender._id}
                                className={"text-blue_link hover:text-blue_link_light"}
                            >
                                {item.data?.reviewsCount === 0 || !item.data?.positiveReviewRatio
                                    ?
                                    t("label.noReviewsYet")
                                    :
                                    item.data?.reviewsCount + " "+t("label.reviews")+" (" + item.data?.positiveReviewRatio + "% "+t("label.positive")+")"}
                            </Link>
                        </div>
                        <div className={"grid grid-cols-[200px_auto] gap-1 my-2"}>
                            <span className={"ml-1"}>{t("label.createItem.label.minimal_renting_period")}:</span>
                            <span>{stringWithDayOrDays(item?.data?.minimal_renting_period ?? 0)}</span>
                            <span className={"ml-1"}>{t("label.createItem.label.availableFrom")}:</span>
                            <span>{toUTCDate(item?.data?.availability_duration_begin ?? "").toLocaleDateString()}</span>
                            <span className={"ml-1"}>{t("label.createItem.label.availableUntil")}:</span>
                            <span>{toUTCDate(item?.data?.availability_duration_end ?? "").toLocaleDateString()}</span>
                            <span className={"ml-1"}>{t("label.createItem.label.category")}:</span>
                            <span>{t(`categories.${item?.data?.category}`)}</span>
                            <span className={"ml-1"}>{t("label.createItem.label.location")}:</span>
                            <span>{item.data?.zip_code + " " + item.data?.city}</span>
                            <div className={"mt-2 col-span-2 border-b-[1px] border-lightgray"} />
                            <span className={"ml-1 mt-2"}>{t("label.createItem.label.daily_price")}:</span>
                            <div className={"mt-2 flex flex-wrap items-center"}>
                                <span
                                    className={"mr-8 font-semibold"}>{formatCurrency(item?.data?.daily_price)}/{t("label.day")}</span>
                                <Button type={"primary"} className="text-white w-32 font-semibold bg-primary"
                                        onClick={() => handleRentNow()}>{t("action.rentNow")}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"text-xl font-semibold w-full pl-2 max-w-2xl"}>
                    {t("label.lenderProductDesc")}
                </div>
                <div className={"pt-2 w-full pl-2 max-w-3xl"}>
                    {item?.data?.description}
                </div>
            </div>
            <BorrowItem item={item.data} open={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </MainLayout>
    )

}

export default ItemDetails