import {DatePicker, Form, Input, InputNumber, Select, Upload} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {CloudUploadOutlined} from "@ant-design/icons";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {RcFile} from "antd/es/upload/interface";
import useNotification from "../../hooks/useNotification";
import {toUTCDate} from "../../utils/helpers";
import {useCategories} from "../../hooks/useCategories";
import dayjs from "dayjs";


interface ItemFormProps {
    name: string

    onNameChange(value: string): void

    description: string

    onDescriptionChange(value: string): void

    imageUrl: string | undefined

    onImageUrlChange(value: string): void

    onPhotoChange(value: Blob): void

    dailyPrice: number | undefined

    ondailyPriceChange(value: number): void

    category: string

    onCategoryChange(value: string): void

    minimalRentingPeriod: number | undefined

    onMinimalRentingPeriodChange(value: number): void

    availabilityStart: Date | undefined,

    onAvailabilityStartChange(value: Date): void

    availabilityEnd: Date | undefined

    onAvailabilityEndChange(value: Date): void

    isRequired: boolean
}

const ItemForm: FC<ItemFormProps> = ({
                                         name,
                                         description,
                                         dailyPrice,
                                         category,
                                         minimalRentingPeriod,
                                         onNameChange,
                                         onDescriptionChange,
                                         onMinimalRentingPeriodChange,
                                         onCategoryChange,
                                         ondailyPriceChange,
                                         onPhotoChange,
                                         imageUrl,
                                         onImageUrlChange,
                                         availabilityStart,
                                         onAvailabilityStartChange,
                                         availabilityEnd,
                                         onAvailabilityEndChange,
                                         isRequired
                                     }) => {

    const {t} = useTranslation()
    const notifications = useNotification()
    const {data: categories} = useCategories()
    const today = toUTCDate(new Date().toISOString())

    const disabledStartDate = (current: any) => {
        const currentDate = new Date(Date.UTC(current.year(), current.month(), current.date()))
        return currentDate < today
    }
    const disabledEndDate = (current: any) => {
        const currentDate = new Date(Date.UTC(current.year(), current.month(), current.date()))
        if (availabilityStart) {
            return currentDate.getTime() > (availabilityStart.getTime() + 28 * 24 * 60 * 60 * 1000) || currentDate < today || currentDate < availabilityStart
        }
        return currentDate < today
    }

    function showDailyPrice(input: string): string {
        let modifiedString = input
        if (parseFloat(input) < 2) {
            modifiedString = "00" + modifiedString
        } else if (input.length < 3) {
            modifiedString = "0" + modifiedString
        }
        const secondCharFromEnd = modifiedString.length - 2
        modifiedString = modifiedString.slice(0, secondCharFromEnd) + "," + modifiedString.slice(secondCharFromEnd)
        return modifiedString
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
        if (!isJpgOrPng) {
            notifications("error", t("notification.error.wrongFileFormat.title"), t("notification.error.wrongFileFormat.message"))
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            notifications("error", t("notification.error.imageTooBig.title"), t("notification.error.imageTooBig.message"))
        }
        return isJpgOrPng && isLt2M
    }

    const handlePhoteUpload = ({file}: { file: any }) => {
        onImageUrlChange(URL.createObjectURL(file)) // Set the image URL
        onPhotoChange(file)
    }
    return (
        <>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.name")}
                    required={isRequired}
                    className={"!m-0 w-6/12"}
                />
                <div className={"w-full"}>
                    <Input value={name} onChange={e => onNameChange(e.target.value)} className={"w-full"}/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.description")}
                    required={isRequired}
                    className={"!m-0 w-6/12"}
                />
                <div className={"w-full"}>
                    <TextArea
                        value={description}
                        showCount
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        style={{resize: "none"}}
                        maxLength={1000}
                        onChange={e => onDescriptionChange(e.target.value)}
                        placeholder={t("label.writeDescriptive") as string}
                    />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.photo")}
                    required={isRequired}
                    className={"!m-0 w-6/12"}
                />
                <div id="upload" className={"w-full sm:justify-self-center"}>
                    <Upload
                        name="photo"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={handlePhoteUpload}
                        beforeUpload={beforeUpload}
                    >
                        {imageUrl ? (
                            <div className={`w-full h-full overflow-hidden relative`}>
                                <img alt={"uploaded image"} src={imageUrl}
                                     className="absolute w-full h-full object-contain"/>
                            </div>
                        ) : <CloudUploadOutlined/>}
                    </Upload>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.daily_price")}
                    required={isRequired}
                    className={"!m-0 w-6/12"}
                />
                <div className={"w-full"}>
                    <InputNumber
                        value={dailyPrice}
                        addonAfter={"€"}
                        controls={false}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        formatter={value => value ? showDailyPrice(value.toString()) : ""}
                        min={0}
                        max={100000}
                        onChange={e => ondailyPriceChange(e ?? 0)}
                        className={"w-full"}
                    />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.category")}
                    required={isRequired}
                    className={"!m-0 w-6/12"}
                />
                <div className={"w-full"}>
                    <Select value={category} onChange={value => onCategoryChange(value)}
                            className={"w-full"}>
                        {categories?.map(category => (
                            <Select.Option key={category}
                                           value={category}>{t(`categories.${category}`)}</Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.minimal_renting_period")}
                    required={isRequired}
                    className={"!m-0 w-6/12"}
                />
                <div className={"w-full"}>
                    <InputNumber value={minimalRentingPeriod} min={0} max={28} controls={false}
                                 onKeyPress={(e) => {
                                     if (!/[0-9]/.test(e.key)) {
                                         e.preventDefault()
                                     }
                                 }}
                                 onChange={e => onMinimalRentingPeriodChange(e ?? 1)}
                                 className={"w-full"}
                    />
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.availabilityStart")}
                    className={"!m-0 w-6/12"}
                    required={isRequired}
                />
                <div className={"w-full"}>
                    <DatePicker value={dayjs(availabilityStart)} allowClear={false} disabledDate={disabledStartDate}
                                onChange={e => onAvailabilityStartChange(toUTCDate(e ? e.toISOString() : ""))}
                                className={"w-full content-center"}/>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <Form.Item
                    label={t("label.createItem.label.availabilityEnd")}
                    className={"!m-0 w-6/12"}
                    required={isRequired}
                />
                <div className={"w-full"}>
                    <DatePicker value={dayjs(availabilityEnd)} allowClear={false} disabledDate={disabledEndDate}
                                onChange={e => onAvailabilityEndChange(toUTCDate(e ? e.toISOString() : ""))}
                                className={"w-full content-center"}/>
                </div>
            </div>
        </>
    )
}

export default ItemForm