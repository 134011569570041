import { queryKey } from "../../utils/consts"
import { useQuery } from "react-query"
import axios from "axios"

export interface User {
    _id: string;
    email: string;
    paypalEmail: string;
    telephone: string;
    firstName: string,
    lastName: string,
    dateOfBirth: Date,
    street: string,
    zipCode: string,
    city: string,
    isAdmin: boolean,
    accountState: MailVerified,
    idVerified: IDVerified,
    avatar: { type: string, data: Buffer }
}


async function getUser(): Promise<User | null> {

    const user = await axios.get(`/auth/myuser`, {})
    return user.data

}

type IDVerified = "verified" | "unverified" | "processing"
type MailVerified = "verified" | "unverified" | "blocked"

interface IUseUser {
    isAuth: boolean
    mailVerified: MailVerified
    idVerified: IDVerified
    user: User | null
    isLoading: boolean
    isError: boolean
}

export function useUser(): IUseUser {

    const myUserQuery = useQuery(
        [queryKey.user],
        async (): Promise<User | null> => getUser(),
        {
            refetchInterval: data => (data?.idVerified === "processing" ? 3000 : false),
            retry: (failureCount, error: any) => {
                return error?.response?.status !== 401;
            },
        })

    if(myUserQuery.isError) {
        return { isError: myUserQuery.isError, isLoading: myUserQuery.isLoading, isAuth: false, mailVerified: "unverified", idVerified: "unverified",  user: null }
    }


    const isAuth = !!myUserQuery.data

    const mailVerified = myUserQuery.data?.accountState || "unverified"

    const idVerified = myUserQuery.data?.idVerified || "processing"

    return { isError: myUserQuery.isError, isLoading: myUserQuery.isLoading, isAuth, mailVerified, idVerified, user: myUserQuery.data ?? null }
}
