import { useMutation, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"
import { queryKey } from "../../utils/consts"
import axios from "axios"
import { User } from "./useUser"

async function sendVerificationMail(email: string | undefined): Promise<any> {
    return await axios.post("/auth/send-verification-mail",
        { email }, {
            headers: { "Content-Type": "application/json" },
        }).catch(reason => {
        throw reason.response.data.error
    })
}

export function useSendVerificationMail() {
    const queryClient = useQueryClient()
    const notification = useNotification()

    return useMutation<User, unknown, { email: string | undefined }>(
        ({
             email
        }) => sendVerificationMail(email), {
            onSuccess: (data: any) => {
                queryClient.setQueryData([queryKey.verificationMail], data)
                notification("success", "Resent verification mail", "Verification mail got send again.")
            },
            onError: (error) => {
                notification("error", "Failed resending verification mail. Log out and in again to try it again.", "Verification mail cannot be resended. Reason: " + error)
            },
        })
}

export default useSendVerificationMail