import React, { useState } from "react"
import { Button, Form, Input } from "antd"
import useForgotPassword from "../../hooks/auth/useForgotPassword"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import logo from "../../assets/logo/logo.svg"

const ForgotPassword = () => {

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const forgotPassword = useForgotPassword()

    const [emailInput, setEmailInput] = useState<string>("")

    const onFinish = () => {
        forgotPassword.mutate({
            email: emailInput,
        })
    }

    return (
        <>
            <div className={"flex items-center justify-center flex-col my-16"}>
                <div className={"flex flex-col gap-8 items-center"}>
                    <Link to={"/"}><img src={logo} className="w-56 pl-1" alt="logo" /></Link>
                    <h2 className={"text-2xl font-semibold"}>{t("action.resetPassword")}</h2>
                    <Form
                        name="reset_password_mail"
                        className="w-80"
                        initialValues={{ remember: true }}
                        layout={"vertical"}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item
                            name="mail"
                            label={t("label.mail")}
                            rules={[{
                                required: true,
                                message: t("notification.error.required", { field: t("label.mail") }) as string,
                            }]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"} placeholder={t("label.mail") ?? ""}
                                   value={emailInput}
                                   onChange={e => setEmailInput(e.target.value)} />
                        </Form.Item>
                        <Form.Item className={"pt-4 mb-4"}>
                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                                className={"bg-primary text-white !rounded-3xl w-full"}
                                size={"large"}
                                loading={forgotPassword.isLoading}
                                disabled={!emailInput}
                            >
                                {t("action.resetPasswordMail")}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Link to={"/login"}>
                                <Button
                                    className={"!rounded-3xl w-full"}
                                    size={"large"}
                                >
                                    {t("action.back")}
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
