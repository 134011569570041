import { Link } from "react-router-dom"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNewestOfferingItems } from "../../hooks/useItems"
import { formatCurrency } from "../../utils/helpers"


const ItemCards = () => {
    const { t } = useTranslation()

    const { data: itemSearch } = useNewestOfferingItems({})

    return (
        <div className=" w-max-[10rem]">
            <h2 className="text-3xl mt-10 lg:mx-20 md:px-16 gap-4">{t("label.newestOfferings")}</h2>
            <div className="flex flex-wrap justify-center mt-10 lg:mx-20 md:px-16 gap-4">
                {itemSearch?.items.map((item, index) => (
                    <Link
                        to={`/item-details?itemId=${item?._id}`}
                        key={index}
                        className="flex flex-col transform transition-transform duration-200 hover:scale-105
                                w-40 sm:w-52 md:w-60 border-cgrey"
                    >
                        <img src={`api/item/photo/${item.photo}`} className="object-cover aspect-square shadow" alt={"item"} />
                        <div className="flex justify-between text-sm font-semibold mt-2 w-full">
                            <div className="relative overflow-hidden whitespace-nowrap flex-grow">
                                <div title={item?.name} className={"hovertest"} style={{
                                    WebkitMaskImage: "linear-gradient(to left, transparent, black 1.5rem)",
                                    maskImage: "linear-gradient(to left, transparent, black 1.5rem)"
                                }}>
                                    {item?.name}
                                </div>
                            </div>
                            <span className="ml-4 whitespace-nowrap text-green-700">{formatCurrency(item?.daily_price)}</span>
                        </div>
                        <p className={"text-sm text-cgrey"}>{item?.lender.firstName} {item.lender.lastName} {t("label.fromCity")} {item?.city}</p>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default ItemCards