import { Link } from "react-router-dom"
import logo from "../../assets/logo/logo.svg"
import React from "react"
import { User, useUser } from "../../hooks/auth/useUser"
import { useQuery } from "react-query"
import { queryKey } from "../../utils/consts"
import axios from "axios"
import AdminItem from "./AdminItem"
import { Item } from "../../hooks/useItems"
import { useTranslation } from "react-i18next"

export interface ExtendedItem extends Item {
    borrower: Pick<User, "_id" | "firstName" | "lastName">
    lender: Pick<User, "_id" | "firstName" | "lastName" | "avatar">
    flaggedReason: string | null
}


const AdminPanel = () => {
    const { user } = useUser()
    const { t } = useTranslation()

    const {data: flaggedItems} = useQuery([queryKey.flaggedItems], async (): Promise<ExtendedItem[]> => {
        const res = await axios.get("/item/flagged")
        return res.data
    })

    return (
        <div>
            <div className="hidden md:flex items-center justify-between flex-nowrap gap-14 p-4 h-16 border-b-[1px] border-lightgray">
                <Link to="/" className="flex flex-nowrap mr-5">
                    <img src={logo} className="w-40 min-w-[10rem] pl-1" alt="logo" />
                </Link>
                <div className="flex flex-nowrap flex-row flex-grow justify-center gap-1 font-semibold text-xl">
                    {t("label.updateAdmin.adminPanel")}
                </div>
                <div className="flex flex-nowrap gap-10 items-center mr-6">
                    {`${user?.firstName} ${user?.lastName}`}
                </div>
            </div>
            <h1 className={"text-xl font-semibold m-3"}>{t("label.updateAdmin.reportedIssues")}</h1>
            {flaggedItems && flaggedItems.map(item => <AdminItem itemData={item} />)}
        </div>
    )
}
export default AdminPanel