import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { useUser } from "../hooks/auth/useUser"
import { Spin } from "antd"
import MainLayout from "./MainLayout"

const ProtectedRoute = () => {
    const { isAuth, isLoading } = useUser();
    const location = useLocation();
    const redirectUrl = location.pathname + location.search


    if (isLoading) {
        return (
            <MainLayout>
                <div className="flex items-center justify-center h-screen">
                    <Spin size="large" />
                </div>
            </MainLayout>
        )
    }
    return isAuth ? (
        <>
            <Outlet />
        </>
    ) : (
        <Navigate to={`login${redirectUrl ? `?redirect=${redirectUrl}` : ""}`} state={{ from: location }} replace />
    );
};

export default ProtectedRoute;