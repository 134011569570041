export const queryKey = {
    user: "user",
    items: "items",
    myItems: "myItems",
    passwordResetMail: "passwordResetMail",
    resetPassword: "resetPassword",
    verificationMail: "verificationMail",
    changeAvatar: "changeAvatar",
    deleteAvatar: "deleteAvatar",
    categories: "categories",
    singleItem: "singleItem",
    flaggedItems: "flaggedItems",
    reviews: "reviews",
    pickupReturn: "pickupReturn"
}

export enum AuthErrorTypes {
    MissingAuthToken = "MissingAuthToken",
    InvalidAuthToken = "InvalidAuthToken",
    ExpiredAuthToken = "ExpiredAuthToken",
    UnverifiedAccount = "UnverifiedAccount",
    BlockedAccount = "BlockedAccount",
    AccountNotFound = "AccountNotFound"
}
export enum VerifyMailErrorTypes {
    NoMail = "NoMail",
    AccountNotFound = "AccountNotFound",
    AccountAlreadyVerified = "AccountAlreadyVerified",
    MailNotSent = "MailNotSent",
    MailAlreadySent = "MailAlreadySent"
}

export const categories = [
    {
        id: "tools",
        title: "Tools",
    },
    {
        id: "garden",
        title: "Garden Tools",
    },
    {
        id: "jewelry",
        title: "Jewlry",
    },
    {
        id: "sport",
        title: "Sport",
    },
    {
        id: "kitchen",
        title: "Kitchen",
    },
]

export const urlPrefix = "http://localhost:3000/"