import React, { FC } from "react"
import { Empty } from "antd"
import notFoundIg from "../assets/icons/notFoundIcon.png"

type PlaceholderProps = {
    text: string
}

const NotFoundPlaceholder: FC<PlaceholderProps> = ({ text }) => {


    return (
        <Empty
            image={notFoundIg}
            imageStyle={{ height: 100 }}
            className={"flex items-center flex-col"}
            description={
                <div>
                    {text}
                </div>
            }
        />
    )
}

export default NotFoundPlaceholder