import React from "react"
import { useSearchParams } from "react-router-dom"
import { Tag } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

const MinimalRentingPeriodFilter: React.FC = () => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedMinimalRentingPeriod = searchParams.get("minRentingPeriod")

    const minimalRentingPeriods = [
        {
            id: "1",
            title: "1 " + t("label.day") + " max",
        },
        {
            id: "2",
            title: "2 " + t("label.days") + " max",
        },
        {
            id: "3",
            title: "3 " + t("label.days") + " max",
        },
        {
            id: "5",
            title: "5 " + t("label.days") + " max",
        },
        {
            id: "7",
            title: "7 " + t("label.days") + " max",
        },
        {
            id: "14",
            title: "14 " + t("label.days") + " max",
        },
    ]

    const addMinimalRentingPeriod = (minRentingPeriod: string) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("minRentingPeriod", minRentingPeriod.toString())
        newSearchParams.set("page", "1")
        setSearchParams(newSearchParams)
    }

    const removeMinimalRentingPeriod = () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete("minRentingPeriod")
        setSearchParams(newSearchParams)
    }

    return (
        <details open>
            <summary className="font-semibold mb-2">
                {t("label.rentingPeriod")}
            </summary>
            {selectedMinimalRentingPeriod
                ? <div>
                    <Tag className="flex justify-between w-full py-0.5" onClick={removeMinimalRentingPeriod}>
                        <div className="text-darkgray">
                            {minimalRentingPeriods.find(p => p.id == selectedMinimalRentingPeriod)?.title}
                        </div>
                        <CloseOutlined />
                    </Tag>
                </div>
                : <div>
                    {minimalRentingPeriods.map(minRentingPeriod => (
                        <div onClick={() => addMinimalRentingPeriod(minRentingPeriod.id)}
                             className="block py-0.5 text-sm pl-1 hover:bg-gray-100 whitespace-nowrap">
                            {minRentingPeriod.title}
                        </div>
                    ))}
                </div>
            }
        </details>
    )
}

export default MinimalRentingPeriodFilter
