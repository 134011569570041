import React, { FC, useState } from "react"
import { Button, Form, Input } from "antd"
import { useTranslation } from "react-i18next"
import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import axios from "axios"
import useNotification from "../../../hooks/useNotification"
import { queryKey } from "../../../utils/consts"
import { queryClient } from "../../../App"
import { emailValid } from "../../../utils/helpers"

interface PaypalEmail {
    paypalEmail: string
}

const PaypalEmail: FC<PaypalEmail> = ({ paypalEmail }) => {
    const { t } = useTranslation()

    const [edit, setEdit] = useState<boolean>(false)
    const [paypalEmailInput, setPaypalEmailInput] = useState<string>(paypalEmail)

    const notification = useNotification()

    const addPaypalEmail = useMutation([queryKey.user],
        (paypalEmail: string) => {
            return axios.post("/auth/changePaypalEmail", { paypalEmail: paypalEmail })
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKey.user])
                setEdit(false)
            },
            onError: () => notification("error", "Failed changing paypal email", ""),
        },
    )

    const onFinish = () => {
        if (emailValid(paypalEmailInput)) {
            addPaypalEmail.mutate(paypalEmailInput)
        }
    }

    return (
        <div className={"flex justify-between"}>
            <div className={"flex"}>
                {edit ?
                    <Form
                        onFinish={onFinish}
                    >
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name="paypalEmail"
                            rules={[{ required: true, message: "Please input your PayPal email!" },
                                { type: "email", message: "The input is not valid PayPal E-mail!" }]}
                            className={"mb-4"}
                            initialValue={paypalEmail}
                        >
                            <Input autoFocus size={"small"} className={"rounded"}
                                   placeholder={t("label.paypalMail") ?? ""}
                                   type={"email"}
                                   defaultValue={paypalEmail}
                                   value={paypalEmailInput}
                                   onChange={e => setPaypalEmailInput(e.target.value)}
                                   maxLength={40} />
                        </Form.Item>
                    </Form>
                    :
                    <div className={"flex pt-1 pl-2 align-middle"}>
                        {paypalEmail}
                    </div>
                }
            </div>
            <div>
                {edit ?
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => onFinish()}
                            icon={<CheckOutlined />}
                            title={t("action.submit") ?? ""} />
                    :
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => setEdit(true)}
                            icon={<EditOutlined />}
                            title={t("action.edit") ?? ""} />}
            </div>
        </div>
    )
}

export default PaypalEmail