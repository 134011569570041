import React, { useLayoutEffect, useRef, useState } from "react"
import TimelineDot from "./TimelineDot"
import { getItemStatus, toLocalDate } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import { Item } from "../../hooks/useItems"

interface Dot {
    left: string;
    content: string;
    active: boolean;
    red?: boolean;
}

interface TimelineProps {
    item: Item
}

const Timeline: React.FC<TimelineProps> = ({item}) => {
    const { t } = useTranslation()
    const [timelineWidth, setTimelineWidth] = useState(0);
    const timelineRef = useRef<HTMLDivElement>(null);

    const updateSize = () => {
        if (timelineRef.current) {
            setTimelineWidth(timelineRef.current.getBoundingClientRect().width);
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        setTimeout(updateSize, 150);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const getDots = (): Dot[] => {
        const startOfRent = toLocalDate(item?.planned_duration_begin ?? "", true, false)
        const pickedUp = toLocalDate(item?.actual_duration_begin ?? "", true)
        const returned = toLocalDate(item?.actual_duration_end ?? "", true)
        const endOfRent = toLocalDate(item.planned_duration_end ?? "", true, false)
        switch (getItemStatus(item)) {
            case "borrowable":
                return [
                    { left: "20%", content: t("label.myItem.borrowableWait"), active: false },
                ]
            case "pickUp":
                return [
                    { left: "20%", content: `${t("label.myItem.startOfRent")}: ${startOfRent}`, active: false },
                ]
            case "inLending":
                return [
                    { left: "20%", content: `${t("label.myItem.startOfRent")}: ${startOfRent}`, active: false },
                    { left: "40%", content: `${t("label.myItem.pickedUp")}: ${pickedUp}`, active: false },
                    { left: "60%", content: `${t("label.myItem.currentStatus")}: ${t(`label.myItem.${getItemStatus(item)}`)}`, active: true },
                    { left: "80%", content: `${t("label.myItem.endOfRent")}: ${endOfRent}`, active: true },
                ]
            case "returned":
                if (new Date(item.actual_duration_end ?? "") > new Date(item.planned_duration_end ?? "")) {
                    return [
                        { left: "20%", content: `${t("label.myItem.startOfRent")}: ${startOfRent}`, active: false },
                        { left: "40%", content: `${t("label.myItem.pickedUp")}: ${pickedUp}`, active: false },
                        { left: "60%", content: `${t("label.myItem.endOfRent")}: ${endOfRent}`, active: false },
                        { left: "80%", content: `${t("label.myItem.returned")}: ${returned}`, active: true },
                    ]
                }
                return [
                    { left: "20%", content: `${t("label.myItem.startOfRent")}: ${startOfRent}`, active: false },
                    { left: "40%", content: `${t("label.myItem.pickedUp")}: ${pickedUp}`, active: false },
                    { left: "60%", content: `${t("label.myItem.returned")}: ${returned}`, active: true },
                    { left: "80%", content: `${t("label.myItem.endOfRent")}: ${endOfRent}`, active: false },
                ]
            case "exceeded":
                return [
                    { left: "20%", content: `${t("label.myItem.startOfRent")}: ${startOfRent}`, active: false },
                    { left: "40%", content: `${t("label.myItem.pickedUp")}: ${pickedUp}`, active: false },
                    { left: "80%", content: `${t("label.myItem.endOfRent")}: ${endOfRent}`, active: false },
                    { left: "90%", content: `${t("label.myItem.exceeded")}`, active: true, red: true },
                ]
            default:
                return []
        }
    }

    return (
        <div
            ref={timelineRef}
            className={`relative ml-10 mt-7 flex items-center border-b-[1px] transition-all duration-2000 border-lightgray w-full h-3 animateTimeline`}>
            {getDots().map((dot, index) => (
                <TimelineDot
                    key={index}
                    left={dot.left}
                    delay={750 * parseFloat(dot.left) / 100}
                >
                    <div className={`w-3 h-3 ${dot.active ? (dot.red ? "bg-red-500" : "bg-primary") : "bg-grayedOut"} rounded-full`}></div>
                    <div
                        className={`${index % 2 === 0 ? "bottom-full" : "top-full"} flex flex-col items-center absolute left-1/2 transform -translate-x-1/2
                         ${timelineWidth <= 500 && getDots().length - 2 > index ? "hidden" : ""}`}>
                        {index % 2 === 0 ? (
                            <>
                                <div
                                    className={`text-center whitespace-nowrap text-sm ${dot.active ? "opacity-100" : "opacity-50"}`}>{dot.content}</div>
                                <div className="h-4 w-px bg-lightgray"></div>
                            </>
                        ) : (
                            <>
                                <div className="h-4 w-px bg-lightgray"></div>
                                <div
                                    className={`text-center whitespace-nowrap text-sm ${dot.active ? "opacity-100" : "opacity-50"}`}>{dot.content}</div>
                            </>
                        )}
                    </div>
                </TimelineDot>
            ))}
        </div>
    )
}

export default Timeline;
