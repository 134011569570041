import React, { FC, useEffect, useMemo, useState } from "react"
import { Button, DatePicker, Modal } from "antd"
import { useTranslation } from "react-i18next"
import { formatCurrency, toUTCDate } from "../utils/helpers"
import usePayment from "../hooks/payment/usePayment"
import { Item } from "../hooks/useItems"


const { RangePicker } = DatePicker

interface DateRange {
    startDate: Date | null;
    endDate: Date | null;
}

interface BorrowItemProps {
    item: Item | undefined
    open: boolean

    onClose(): void
}

const BorrowItem: FC<BorrowItemProps> = ({ item, open, onClose }) => {
    const actualItem = item!
    const { createPayment, loading } = usePayment()
    const { t } = useTranslation()
    
    const disabledDate = (current: any) => {
        if (!actualItem) return false
        const currentDate = new Date(Date.UTC(current.year(), current.month(), current.date())) // current is a moment object
        const startDate = toUTCDate(actualItem.availability_duration_begin)
        const endDate = toUTCDate(actualItem.availability_duration_end)
        return (currentDate < startDate || currentDate > endDate) || currentDate < toUTCDate(new Date().toString())
    }

    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: null,
        endDate: null,
    })

    const handleDateChange = (dates: any) => {
        const [start, end] = dates
        setDateRange({
            startDate: toUTCDate(start),
            endDate: toUTCDate(end),
        })
    }

    const calculateDateDifference = useMemo(() => {
        if (dateRange.startDate && dateRange.endDate) {
            const diffInTime = dateRange.endDate.getTime() - dateRange.startDate.getTime()
            return Math.floor(diffInTime / (1000 * 3600 * 24))
        }
        return 0
    }, [dateRange])

    useEffect(() => {
        if (!actualItem) {
            onClose()
        }
    }, [])

    if (!item) {
        return <></>
    }

    return (
        <Modal
            title={`${t("label.borrowItem")}: ${actualItem?.name}`}
            open={open}
            onCancel={() => onClose()}
            footer={[
                <Button key="back" onClick={() => onClose()}>
                    {t("action.cancel")}
                </Button>,
                <Button
                    type="ghost"
                    onClick={() => actualItem && dateRange.startDate && dateRange.endDate && createPayment(actualItem._id, dateRange.startDate, dateRange.endDate)}
                    icon={<img src="https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-150px.png"
                               alt={t("action.checkoutWithPaypal") as string} className={"h-3.5"} />}
                    className={`bg-amber-400 !w-32 ${calculateDateDifference < actualItem.minimal_renting_period ? "bg-lightgray" : "hover:bg-amber-300"}`}
                    disabled={calculateDateDifference < actualItem.minimal_renting_period || loading}
                />,
            ]}
        >
            <div className={"my-8"}>
                <div className={"mx-6 mb-6"}>
                    <h2 className={"mb-2"}>{t("label.selectRentalPeriod")}</h2>
                    <RangePicker disabledDate={disabledDate} onChange={handleDateChange} allowClear={false} />
                    <p className={`mt-1 text-xs text-red-400 ${calculateDateDifference >= actualItem.minimal_renting_period ? "hidden" : ""}`}>
                        {t("label.minimalRentingPeriodIs")} {actualItem.minimal_renting_period} {actualItem.minimal_renting_period > 1 ? t("label.days") : t("label.day")}
                    </p>
                </div>
                <div className="w-full divide-y divide-grayedOut">
                    <div className="bg-white divide-y divide-gray-200">
                        <div className="flex items-center px-6 py-2">
                            <div className="w-3/4 text-sm">{t("label.rentalPeriod")}</div>
                            <div className="text-sm">
                                {!!calculateDateDifference && `${calculateDateDifference} ${calculateDateDifference > 1 ? t("label.days") : t("label.day")}`}
                            </div>
                        </div>
                        <div className="flex items-center px-6 py-2">
                            <div className="w-3/4 text-sm">{t("label.dailyPrice")}</div>
                            <div className="text-sm">{formatCurrency(actualItem?.["daily_price"])}</div>
                        </div>
                        <div className="flex items-center px-6 py-2">
                            <div className="w-3/4 text-sm">
                                <span className="font-bold">{t("label.totalItemPrice")}</span>
                            </div>
                            <div className="text-sm">
                                {!!calculateDateDifference && (
                                    <span className="font-bold">
                                        {formatCurrency(calculateDateDifference * actualItem?.["daily_price"])}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 w-full divide-y divide-gray-200">
                    <div className="bg-white divide-y divide-gray-200">
                        <div className="flex items-center px-6 py-2">
                            <div className="w-3/4 text-sm">{t("label.securityDeposit")}</div>
                            <div className="text-sm">{formatCurrency(actualItem?.deposit)}</div>
                        </div>
                        <div className="flex items-center px-6 py-2">
                            <div className="w-full text-xs text-grayedOut">{t("label.paymentInfo")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BorrowItem