import { Navigate, Outlet } from "react-router-dom"
import React from "react"
import { useUser } from "../hooks/auth/useUser"
import useNotification from "../hooks/useNotification"

const VerifiedRoute = () => {
    const { idVerified, mailVerified } = useUser()
    const notification = useNotification()

    if (idVerified === "verified" && mailVerified === "verified") {
        return <Outlet />
    } else {
        notification("error", "Verification needed", "You need to verify your account in order to borrow items.")
        return <Navigate to="/profile/verification" />
    }

}

export default VerifiedRoute