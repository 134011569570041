import React from 'react';
import MainLayout from "../MainLayout";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const PaymentFailed = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const isReturning = location.pathname === "/return-item" || location.pathname === "/return-item/";

    return (
        <MainLayout>
            <div className={"flex flex-col items-center p-4"}>
                <Card>
                    <h1 className={"text-2xl"}>{t("notification.error.didNotWork")}</h1>
                    <p>{isReturning ? t("notification.error.return") : t("notification.error.paymentFailed")}</p>
                </Card>
            </div>
        </MainLayout>
    )
}

export default PaymentFailed;
