import React, { FC, useState } from "react"
import { Button, Form, Input } from "antd"
import { useTranslation } from "react-i18next"
import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import axios from "axios"
import useNotification from "../../../hooks/useNotification"
import { queryKey } from "../../../utils/consts"
import { queryClient } from "../../../App"
import { telephoneValid } from "../../../utils/helpers"

interface Telephone {
    telephone: string
}

const Telephone: FC<Telephone> = ({ telephone }) => {
    const { t } = useTranslation()

    const [edit, setEdit] = useState<boolean>(false)
    const [telephoneInput, setTelephoneInput] = useState<string>(telephone)

    const notification = useNotification()

    const addTelephone = useMutation([queryKey.user],
        (telephone: string) => {
            return axios.post("/auth/changeTelephone", { telephone: telephone })
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKey.user])
                setEdit(false)
            },
            onError: () => notification("error", "Failed changing telephone number", ""),
        },
    )

    const onFinish = () => {
        if (telephoneValid(telephoneInput)) {
            addTelephone.mutate(telephoneInput)
        }
    }

    return (
        <div className={"flex justify-between"}>
            <div className={"flex"}>
                {edit ?
                    <Form
                        onFinish={onFinish}
                    >
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name="telephone"
                            rules={[{ required: true, message: "Please input your telephone number!" },
                                {
                                    pattern: new RegExp(/^\+[1-9][0-9]{9,14}$/),
                                    message: "Please input a valid phone number i.e. +491625555555",
                                }]}
                            className={"mb-4"}
                            initialValue={telephone}
                        >
                            <Input autoFocus size={"small"} className={"rounded"}
                                   placeholder={t("label.firstName") ?? ""}
                                   defaultValue={telephone}
                                   value={telephoneInput}
                                   onChange={e => setTelephoneInput(e.target.value)}
                                   maxLength={16} />
                        </Form.Item>
                    </Form>
                    :
                    <div className={"flex pt-1 pl-2 align-middle"}>
                        {telephone}
                    </div>
                }
            </div>
            <div>
                {edit ?
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => onFinish()}
                            icon={<CheckOutlined />}
                            title={t("action.submit") ?? ""} />
                    :
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => setEdit(true)}
                            icon={<EditOutlined />}
                            title={t("action.edit") ?? ""} />}
            </div>
        </div>
    )
}

export default Telephone