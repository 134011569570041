import {Button, DatePicker, Form, Input, InputNumber, Modal} from "antd"
import {useTranslation} from "react-i18next"
import React, { useState} from "react"
import "./ItemForm.css"
import ItemForm from "./ItemForm";
import {toUTCDate} from "../../utils/helpers";
import useItemMutations from "../../hooks/useItemMutations";
import useNotification from "../../hooks/useNotification";
import {ExtendedItem} from "../Admin/AdminPanel";
import dayjs from "dayjs";


interface UpdateAdminItemProps {
    item: ExtendedItem | undefined
    open: boolean

    onClose(): void
}

const UpdateAdminItem = ({item, open, onClose}: UpdateAdminItemProps) => {
    const {t} = useTranslation()
    const {itemAdminMutation, itemPhotoCreation} = useItemMutations()
    const notification = useNotification()
    if (!item){
        notification("error", "The item is not loaded", "Please try again, after the Item is loaded properly!" )
        onClose()
    }

    const [name, setName] = useState<string>(item?.name??"")
    const [description, setDescription] = useState<string>(item?.description??"")
    const [imageUrl, setImageUrl] = useState<string>(`api/item/photo/${item?.photo}`)
    const [photo, setPhoto] = useState<Blob | undefined>(undefined)
    const [minimalRentingPeriod, setMinimalRentingPeriod] = useState<number>(item?.minimal_renting_period??1)
    const [dailyPrice, setDailyPrice] = useState<number>(item?.daily_price??0)
    const [category, setCategory] = useState<string>(item?.category??"")
    const [availabilityStart, setAvailabilityStart] = useState<Date>(item ? toUTCDate(item.availability_duration_begin) : new Date())
    const [availabilityEnd, setAvailabilityEnd] = useState<Date>(item ? toUTCDate(item.availability_duration_end) : new Date())
    const [street, setStreet] = useState<string>(item?.street??"");
    const [zip_code, setZipCode] = useState<string>(item?.zip_code??"");
    const [city, setCity] = useState<string>(item?.city ?? "");
    const [plannedPrice, setPlannedPrice] = useState<number>(item?.planned_price??0);
    const [actualPrice, setActualPrice] = useState<number>(item?.actual_price??0);
    const [deposit, setDeposit] = useState<number>(item?.deposit??0);
    const [rentStart, setRentStart] = useState<Date>(item?.rentStart ? toUTCDate(item.rentStart) : new Date());
    const [plannedDurationBegin, setPlannedDurationBegin] = useState<Date>(item?.planned_duration_begin ? toUTCDate(item.planned_duration_begin) : new Date());
    const [plannedDurationEnd, setPlannedDurationEnd] = useState<Date>(item?.planned_duration_end ? toUTCDate(item.planned_duration_end) : new Date());
    const [actualDurationBegin, setActualDurationBegin] = useState<Date>(item?.actual_duration_begin ? toUTCDate(item.actual_duration_begin) : new Date());
    const [actualDurationEnd, setActualDurationEnd] = useState<Date>(item?.actual_duration_end ? toUTCDate(item.actual_duration_end) : new Date());
    const [flaggedReason, setFlaggedReason] = useState<string>(item?.flaggedReason ??"");


    const handleSubmit = async () => {
        // Formdata needed so axios can send binary
        let responsePhoto
        if (photo) {
            const formData = new FormData()
            formData.append("photo", photo)
            responsePhoto = await itemPhotoCreation.mutateAsync(formData)
        }

        const newItem: Partial<ExtendedItem> = {
            name: name,
            description: description,
            photo: responsePhoto?.data.photoId,
            minimal_renting_period: minimalRentingPeriod,
            daily_price: dailyPrice,
            category: category,
            availability_duration_begin: availabilityStart?.toISOString(),
            availability_duration_end: availabilityEnd?.toISOString(),
            street: street,
            zip_code: zip_code,
            city: city,
            planned_price: plannedPrice,
            actual_price: actualPrice,
            deposit: deposit,
            rentStart: rentStart?.toISOString(),
            planned_duration_begin: plannedDurationBegin?.toISOString(),
            planned_duration_end: plannedDurationEnd?.toISOString(),
            actual_duration_begin: actualDurationBegin?.toISOString(),
            actual_duration_end: actualDurationEnd?.toISOString(),
            flaggedReason: flaggedReason,
        }
        itemAdminMutation.mutate({itemData: newItem, itemId: item?._id??""})
    }

    // useEffect(()=>{
    //     if(itemUpdate.isSuccess){
    //         onClose()
    //     }
    // }, [itemUpdate])

    return (
        <Modal
            title={t("label.updateItem.headline")}
            open={open}
            onCancel={() => onClose()}
            // className={"!w-[50rem] flex"}
            width={1000}
            footer={[
                <Button key="back" onClick={() => onClose()}>
                    Cancel
                </Button>,
                <Button type="primary" className={"bg-primary"} onClick={handleSubmit}>
                    {t("action.change-item")}
                </Button>
            ]}
        >
            <div className="flex flex-col gap-5">
                <ItemForm name={name ?? ""} onNameChange={setName}
                          description={description ?? ""} onDescriptionChange={setDescription}
                          onPhotoChange={setPhoto}
                          imageUrl={imageUrl} onImageUrlChange={setImageUrl}
                          dailyPrice={dailyPrice} ondailyPriceChange={setDailyPrice}
                          category={category ?? ""} onCategoryChange={setCategory}
                          minimalRentingPeriod={minimalRentingPeriod}
                          onMinimalRentingPeriodChange={setMinimalRentingPeriod}
                          availabilityStart={availabilityStart} onAvailabilityStartChange={setAvailabilityStart}
                          availabilityEnd={availabilityEnd}
                          onAvailabilityEndChange={setAvailabilityEnd} isRequired={false}/>
                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.street")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <Input value={street} onChange={e => setStreet(e.target.value)} className={"w-full"}/>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.zipCode")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <Input value={zip_code} onChange={e => setZipCode(e.target.value)} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.city")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <Input value={city} onChange={e => setCity(e.target.value)} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.plannedPrice")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <InputNumber value={plannedPrice} onChange={value => setPlannedPrice(value??0)} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.actualPrice")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <InputNumber value={actualPrice} onChange={value => setActualPrice(value??0)} className={"w-full"} />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.deposit")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <InputNumber value={deposit} onChange={value => setDeposit(value??0)} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.rentStart")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <DatePicker onChange={date => setRentStart(date?.toDate()??new Date())} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.plannedDurationBegin")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <DatePicker onChange={date => setPlannedDurationBegin(date?.toDate()??new Date())} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.plannedDurationEnd")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <DatePicker  onChange={date => setPlannedDurationEnd(date?.toDate()??new Date())} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.actualDurationBegin")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <DatePicker onChange={date => setActualDurationBegin(date?.toDate()??new Date())} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.actualDurationEnd")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <DatePicker onChange={date => setActualDurationEnd(date?.toDate()??new Date())} className={"w-full"} />
                    </div>
                </div>

                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                    <Form.Item
                        label={t("label.updateAdmin.flaggedReason")}
                        className={"!m-0 w-6/12"}
                    />
                    <div className={"w-full"}>
                        <Input value={flaggedReason} onChange={e => setFlaggedReason(e.target.value)} className={"w-full"} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default UpdateAdminItem
