import { useQuery } from "react-query"
import { queryKey } from "../utils/consts"
import axios from "axios"
import { User } from "./auth/useUser"

interface SearchParams {
    address?: string | null;
    limit?: number | null;
    page?: number | null;
    distance?: number | null;
    minPrice?: number | null;
    maxPrice?: number | null;
    category?: string | null;
    search?: string | null;
    minimal_renting?: number | null;
    availability_start?: string | null;
    availability_end?: string | null;
    sortBy?: string | null;
}

export interface Item {
    _id: string;
    lender: Pick<User, "_id" | "firstName" | "lastName">;
    borrower?: Pick<User, "_id" | "firstName" | "lastName">;
    name: string;
    description: string;
    photo: string;
    minimal_renting_period: number;
    daily_price: number;
    planned_price?: number;
    actual_price?: number;
    deposit: number;
    category: string;
    rentStart?: string;
    availability_duration_begin: string;
    availability_duration_end: string;
    planned_duration_begin?: string;
    planned_duration_end?: string;
    actual_duration_begin?: string;
    actual_duration_end?: string;
    street: string;
    zip_code: string;
    city: string;
    location?: {
        type: string;
        coordinates: number[];
    };
    reviewsCount: number;
    positiveReviewRatio: number;
    createdAt: string
    userAvatar: null | {
        type: string
        data: Buffer
    },
    flaggedLender?: string;
    flaggedLenderDescription?: string;
    flaggedBorrower?: string;
    flaggedBorrowerDescription?: string;
}

export interface ItemSearch {
    pageCount: number
    items: Item[]
}

const useItems = (filter: SearchParams) => {
    return useQuery([queryKey.items, filter], async (): Promise<ItemSearch> => {
        const params = new URLSearchParams();
        Object.entries(filter).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
                params.append(key, String(value));
            }
        });
        const res = await axios.get(`/item${params.toString() ? "?" + params : ""}`)
        return res.data
    })

}

export const useNewestOfferingItems = (filter: SearchParams) => {
    return useQuery([queryKey.items, filter], async (): Promise<ItemSearch> => {
        const params = new URLSearchParams();
        Object.entries(filter).forEach(([key, value]) => {
            if (value !== null && value !== undefined) {
                params.append(key, String(value));
            }
        });
        const res = await axios.get(`/item?limit=12`)
        return res.data
    })

}

export default useItems