import React, { FC, useState } from "react"
import { Button, Form, Input } from "antd"
import { useTranslation } from "react-i18next"
import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import axios from "axios"
import useNotification from "../../../hooks/useNotification"
import { queryKey } from "../../../utils/consts"
import { queryClient } from "../../../App"

interface LastName {
    lastName: string
}

const LastName: FC<LastName> = ({ lastName }) => {
    const { t } = useTranslation()

    const [edit, setEdit] = useState<boolean>(false)
    const [lastNameInput, setLastNameInput] = useState<string>(lastName)

    const notification = useNotification()

    const addLastName = useMutation([queryKey.user],
        (lastName: string) => {
            return axios.post("/auth/changeLastName", { lastName: lastName })
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKey.user])
                setEdit(false)
            },
            onError: () => notification("error", "Failed changing last name", ""),
        },
    )

    const onFinish = () => {
        if (lastNameInput.length > 0) {
            addLastName.mutate(lastNameInput)
        }
    }

    return (
        <div className={"flex justify-between"}>
            <div className={"flex"}>
                {edit ?
                    <Form
                        onFinish={onFinish}
                    >
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name="lastName"
                            rules={[{ required: true, message: "Please input your last Name!" }]}
                            className={"mb-4"}
                            initialValue={lastName}
                        >
                            <Input autoFocus size={"small"} className={"rounded"}
                                   placeholder={t("label.firstName") ?? ""}
                                   defaultValue={lastName}
                                   value={lastNameInput}
                                   onChange={e => setLastNameInput(e.target.value)}
                                   maxLength={30}/>
                        </Form.Item>
                    </Form>
                    :
                    <div className={"flex pt-1 pl-2 align-middle"}>
                        {lastName}
                    </div>
                }
            </div>
            <div>
                {edit ?
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => onFinish()}
                            icon={<CheckOutlined />}
                            title={t("action.submit") ?? ""} />
                    :
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => setEdit(true)}
                            icon={<EditOutlined />}
                            title={t("action.edit") ?? ""} />}
            </div>
        </div>
    )
}

export default LastName