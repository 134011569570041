import { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

const useStripe = (): Stripe | null => {
    const [stripe, setStripe] = useState<Stripe | null>(null);

    useEffect(() => {
        const initializeStripe = async () => {
            const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
            setStripe(stripeInstance);
        };

        initializeStripe();
    }, []);

    return stripe;
};

export default useStripe;