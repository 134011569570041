import { Collapse } from "antd"
import MainLayout from "./MainLayout"
import { useTranslation } from "react-i18next"

const { Panel } = Collapse

export default function FAQ() {
    const { t } = useTranslation()

    return (
        <MainLayout>
            <Collapse>
                {Array.from({length: 9}, (_, i) => i + 1).map(i => (
                    <Panel header={t(`label.faq.questions.${i}`)} key={i}>
                        <p>{t(`label.faq.answers.${i}`)}</p>
                    </Panel>
                ))}
            </Collapse>
        </MainLayout>
    )
}