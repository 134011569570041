import React from "react"
import { useSearchParams } from "react-router-dom"
import { Tag, Input, Slider } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { SliderMarks } from "antd/es/slider"
import { useTranslation } from "react-i18next"

const distanceMarks: SliderMarks = {
    1: {
        label: <div className={"text-xxs"}>1<br/>km</div>
    },
    100: {
        label: <div className={"text-xxs"}>100<br/>km</div>
    },
    250: {
        label: <div className={"text-xxs"}>250<br/>km</div>
    },
    500: {
        label: <div className={"text-xxs"}>500<br/>km</div>
    },
}

const LocationFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedZipCode: string | null = searchParams.get("address")
    const selectedDistance: string | null = searchParams.get("distance")
    const { t } = useTranslation()

    const validateZipCodeInput = (zipCodeInput: string) => {
        if (zipCodeInput.length == 5)
            addZipCode(zipCodeInput)
    }

    const setDistance = (distanceInput: number) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("distance", distanceInput.toString())
        newSearchParams.set("page","1")
        setSearchParams(newSearchParams)
    }

    const addZipCode = (zipCode: string) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("address", zipCode)
        // Default distance is 50km
        newSearchParams.set("distance", "50000")
        newSearchParams.set("page","1")
        setSearchParams(newSearchParams)
    }

    const removeZipCode = () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete("address")
        // Also remove distance as it is not relevant anymore
        newSearchParams.delete("distance")
        setSearchParams(newSearchParams)
    }

    return (
        <details open>
            <summary className="font-semibold mb-2">
                {t("label.location")}
            </summary>
            {selectedZipCode
                ? <div>
                    <div className={"pb-2 text-sm"}>Zip code:</div>
                    <Tag className="flex justify-between w-full py-0.5" onClick={removeZipCode}>
                        <div>{selectedZipCode}</div>
                        <CloseOutlined />
                    </Tag>
                    <div className={"pt-3 text-sm pl-0.5"}>
                        {t("label.distance")}{selectedDistance
                        ? ": " + (parseInt(selectedDistance) / 1000).toString() + " km"
                        : ""}
                    </div>
                    <Slider
                        className={"w-48"}
                        min={1}
                        max={500}
                        marks={distanceMarks}
                        onChange={(e) =>
                            setDistance(e * 1000)
                        }
                        value={selectedDistance
                            ? parseInt(selectedDistance) / 1000
                            : 50
                        }
                    />
                </div>
                : <div>
                    <Input placeholder={"Zip code"}
                           onChange={(e) =>
                               validateZipCodeInput(e.target.value)}
                           onKeyPress={(e) => {
                               if (!/[0-9]/.test(e.key)) {
                                   e.preventDefault()
                               }
                           }}
                    />
                </div>
            }
        </details>
    )
}

export default LocationFilter
