import React, { FC } from "react"
import { Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { formatCurrency, getBase64FromBuffer } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import noImage from "../../assets/no-image.svg"
import { Item } from "../../hooks/useItems"

interface ItemInterface {
    itemData: Item | undefined
}

const BrowseItem: FC<ItemInterface> = ({ itemData }) => {

    const { t } = useTranslation()
    if (itemData === undefined) {
        return <></>
    }

    const stringWithDayOrDays = (minimalRentingPeriod: number): string => {
        if (minimalRentingPeriod == 1) {
            return minimalRentingPeriod + " " + t("label.day")
        } else {
            return minimalRentingPeriod + " " + t("label.days")
        }
    }

    const userProfilePic = getBase64FromBuffer(itemData.userAvatar?.data)

    const renderImage = (
        <div
            className={`w-20 h-20 flex overflow-hidden relative ${!itemData?.photo ? "border-[1px] border-lightgray" : ""} rounded-md`}>
            <img alt={"item"} src={
                itemData?.photo ? `api/item/photo/${itemData?.photo}` : noImage}
                 className={`object-cover ${!itemData?.photo ? "w-10 h-10 m-auto" : ""}`} />
        </div>
    )

    return (
        <>
            <Link
                className={"flex w-full justify-start flex-wrap p-4 border-t-[1px] border-lightgray hover:bg-gray-100"}
                to={`/item-details?itemId=${itemData._id}`}>
                <div className={"flex grow flex-wrap justify-center items-center md:justify-start"}>
                    {renderImage}
                    <div className={"ml-4"}>
                        <h2 className={"text-xl"}>{itemData?.name}</h2>
                        <div className={"flex justify-left"}>
                            <div>
                                <p className={"text-sm pt-2"}>{t("label.createItem.label.minimal_renting_period")}:</p>
                                <p className={"text-sm"}>{t("label.createItem.label.daily_price")}:</p>
                            </div>
                            <div className={"ml-6"}>
                                <p className={"text-sm pt-2"}>{stringWithDayOrDays(itemData.minimal_renting_period)}</p>
                                <p className={"text-sm"}>{formatCurrency(itemData.daily_price)}/{t("label.day")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"grow flex justify-center md:justify-end"}>
                    <div
                        className={"items-center flex m-2 md:ml-8 pl-2 justify-self-center rounded-full border-[1px] w-72 h-16 justify-left border-lightgray"}>
                        <Avatar className={"flex-none"} size={48} icon={
                            userProfilePic
                                ?
                                <img src={userProfilePic}
                                     alt="avatar"
                                     className={"object-cover w-24 h-24 rounded-full"}>
                                </img>
                                :
                                <UserOutlined />
                        } />
                        <div className={"pl-3"}>
                            <p className={"text-sm"}>{t("label.by")} {itemData.lender.firstName}</p>
                            <Link className={"text-sm text-blue_link hover:text-blue_link_light"}
                                  to={"/reviews?userId=" + itemData.lender._id}>
                                {itemData.reviewsCount === 0
                                    ?
                                    t("label.noReviewsYet")
                                    :
                                    itemData.reviewsCount + " "+t("label.reviews")+" (" + itemData.positiveReviewRatio + "% "+t("label.positive")+")"}
                            </Link>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default BrowseItem
