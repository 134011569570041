import { FC, ReactNode, useEffect, useState } from "react"

interface TimelineDotProps {
    left: string;
    delay: number;
    children: ReactNode;
    red?: boolean;
}

const TimelineDot: FC<TimelineDotProps> = ({ left, delay, children }) => {
    const [dotActive, setDotActive] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setDotActive(true), delay);
        return () => clearTimeout(timeout);
    }, [delay]);

    return (
        <>
            <div
                className={`absolute bottom-0 transform -translate-x-1/2 transition-all duration-500 ease-in-out ${dotActive ? 'opacity-100 translate-y-1/2' : 'opacity-0 -translate-y-4'}`}
                style={{ left }}
            >
                {children}
            </div>

        </>

    );
}

export default TimelineDot