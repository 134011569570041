import React, {FC, useMemo, useState} from "react"
import noImage from "../../assets/no-image.svg"
import { ExtendedItem } from "./AdminPanel"
import { getItemStatus } from "../../utils/helpers"
import { Button, Tooltip } from "antd"
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import useItemMutations from "../../hooks/useItemMutations"
import { Item } from "../../hooks/useItems"
import UpdateAdminItem from "../ChangeItem/UpdateAdminItem";
import { useQueryClient } from "react-query"
import { queryKey } from "../../utils/consts"

interface ItemInterface {
    itemData: ExtendedItem | undefined
}


const AdminItem: FC<ItemInterface> = ({ itemData }) => {

    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const queryClient = useQueryClient()

    const renderImage = (
        <div
            className={`w-20 h-20 flex overflow-hidden relative ${!itemData?.photo ? "border-[1px] border-lightgray" : ""} rounded-md`}>
            <img alt={"item"} src={
                itemData?.photo ? `${"http://localhost:8000/api/"}item/photo/${itemData?.photo}` : noImage}
                 className={`absolute inset-0 object-cover ${!itemData?.photo ? "w-10 h-10 m-auto" : ""}`} />
        </div>
    )

    const { itemDeletion, itemAdminMutation} = useItemMutations()

    const currentStatus = useMemo<string>(() => getItemStatus(itemData as Item), [itemData])

    const onResolve = () => {
        if (!itemData) return
        itemAdminMutation.mutate({itemData: { $unset: {
                    "flaggedLender": "",
                    "flaggedLenderDescription": "",
                    "flaggedBorrower": "",
                    "flaggedBorrowerDescription": ""
                }}, itemId: itemData._id},
            {
                onSuccess: () => {
                    queryClient.invalidateQueries([queryKey.flaggedItems])
                },
            })
    }

    return (
        <div
            className={"flex w-screen flex-wrap justify-between items-center border-lightgray border-t-[1px] p-4 hover:bg-gray-100"}>
            <div className={"flex"}>
                {renderImage}
                <div className={"ml-4"}>
                    <h2 className={"text-xl"}>{itemData?.name}</h2>
                    <div className="flex flex-col md:flex-row text-sm">
                        <div className="flex">
                            <div>
                                <p>{t("label.cLender")}:</p>
                                <p className="text-sm">{t("label.myItem.currentStatus")}:</p>
                            </div>
                            <div className="ml-4">
                                <p>{itemData?.lender.firstName} {itemData?.lender.lastName}</p>
                                <p>{t(`label.myItem.${currentStatus}`)}</p>
                            </div>
                        </div>
                        <div className="flex mt-4 md:mt-0 md:ml-20">
                            <div>
                                <p>{t("label.cBorrower")}:</p>
                            </div>
                            <div className="ml-4">
                                <p>{itemData?.borrower ? `${itemData?.borrower.firstName} ${itemData?.borrower.lastName}` : "-"}</p>
                            </div>
                        </div>
                        <div className="flex mt-4 md:mt-0 md:ml-20">
                            <div>
                                {itemData?.flaggedLender && <p className="font-semibold">{itemData?.lender.firstName} {itemData?.lender.lastName} {t("label.updateAdmin.writes")}:</p>}
                                {itemData?.flaggedBorrower && <p className="font-semibold">{itemData?.borrower.firstName} {itemData?.lender.lastName} {t("label.updateAdmin.writes")}:</p>}
                            </div>
                            <div className="ml-4">
                                {itemData?.flaggedLender && <p>{itemData?.flaggedLenderDescription}</p>}
                                {itemData?.flaggedBorrower && <p>{itemData?.flaggedBorrowerDescription}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Tooltip title={t("action.resolve")}>
                    <Button shape="circle" type={"text"} icon={<CheckOutlined className={"text-green-700"}/>} onClick={() => onResolve()} />
                </Tooltip>
                <Tooltip title={t("action.edit")}>
                    <Button shape="circle" type={"text"} icon={<EditOutlined className={"text-grayedOut"}/>} onClick={() => setIsModalOpen(true)}/>
                </Tooltip>
                <Tooltip title={t("action.delete")}>
                    <Button shape="circle" type={"text"} icon={<DeleteOutlined className={"text-red-400"}/>} onClick={() => itemDeletion.mutate({itemId: itemData?._id ?? "",})}/>
                </Tooltip>
            </div>
            <UpdateAdminItem item={itemData} open={isModalOpen} onClose={() => setIsModalOpen(false)} />

        </div>
    )
}

export default AdminItem