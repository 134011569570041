import React, { FC, useEffect, useState } from "react"
import { Modal, QRCode } from "antd"
import { queryKey, urlPrefix } from "../../utils/consts"
import { Item } from "../../hooks/useItems"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { useQuery, useQueryClient } from "react-query"
import { getItemStatus } from "../../utils/helpers"
import useNotification from "../../hooks/useNotification"


interface ModalQRCodeProps {
    open: boolean
    item: Item
    isLender: boolean
    onCancel(): void
}

const ModalQRCode: FC<ModalQRCodeProps> = ({ open, onCancel, item, isLender }) => {

    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const notification = useNotification()

    useQuery([queryKey.singleItem, item._id], async (): Promise<Item> => {
        const { data } = await axios.get(`/item/single-item/${item._id}`)
        return data
    }, {
        enabled: open,
        refetchInterval: () => open ? 3000 : false,
        onSuccess: async (singleItem) => {
            if (getItemStatus(item) !== getItemStatus(singleItem)) {
                await queryClient.invalidateQueries([queryKey.myItems])
                notification("success", "", isLender ? t("label.pickupReturn.return") : t("label.pickupReturn.pickup"))
                onCancel()
            }
        },
    })

    const [qrCodeToken, setQrCodeToken] = useState("")
    let baseURL
    if (process.env.NODE_ENV === "production") {
        baseURL = `https://lendless.de/${isLender ? "return-item" : "pickup-item"}/`
    } else if (process.env.NODE_ENV === "development") {
        baseURL = `${process.env.REACT_APP_NGROK}${isLender ? "return-item" : "pickup-item"}/`
    }

    useEffect(() => {
        if (open) {
            axios.get(`/item/qrcode/${item._id}`)
                .then(res => setQrCodeToken(res.data.token))
        }
    }, [open, item._id])

    const renderTitle = () => {
        return <div
            className={"text-center text-2xl"}>{`${isLender ? t("action.return") : t("action.pickUp")} ${item.name}`}</div>
    }

    return (
        <Modal
            title={renderTitle()}
            footer={false}
            open={open}
            onCancel={onCancel}>
            <p>{isLender ?
                t("label.myItem.qrCodeReturn", { borrower: `${item?.borrower?.firstName} ${item?.borrower?.lastName}` })
                :
                t("label.myItem.qrCodePickup", { lender: `${item.lender.firstName} ${item.lender.lastName}` })
            }
            </p>
            <div className={"flex justify-center"}>
                <QRCode
                    className={"my-4"}
                    size={225}
                    errorLevel="H"
                    value={`${baseURL}?token=${qrCodeToken}`}
                    icon={urlPrefix + "favicons/64x64.png"}
                />
            </div>
        </Modal>
    )
}
export default ModalQRCode