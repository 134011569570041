import logo from "../../assets/logo/logo.svg"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Form, Input } from "antd"
import { Link, useLocation } from "react-router-dom"
import useResetPassword from "../../hooks/auth/useResetPassword"

const Login = () => {
    const { search } = useLocation()
    const parameters = new URLSearchParams(search)
    const token = parameters.get("token")
    const { t } = useTranslation()
    const resetPassword = useResetPassword()
    const [form] = Form.useForm()

    const [passwordInput, setPasswordInput] = useState<string>("")
    const [passwordConfirmInput, setPasswordConfirmInput] = useState<string>("")

    const onFinish = () => {
        resetPassword.mutate({ password: passwordInput, passwordConfirm: passwordConfirmInput, token: token })
    }


    return (
        <>
            <div className={"flex items-center justify-center flex-col my-16"}>
                <div className={"flex flex-col gap-8 items-center"}>
                    <Link to={"/"}><img src={logo} className="w-56 pl-1" alt="logo" /></Link>
                    <Form
                        name="password_reset"
                        className="w-80"
                        initialValues={{ remember: true }}
                        layout={"vertical"}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item
                            name="password"
                            label={t("label.password")}
                            rules={[{
                                required: true,
                                message: t("notification.error.required", { field: t("label.newPassword") }) as string,
                            },
                                { min: 8, message: t("notification.error.passwordLength") as string }]}
                        >
                            <Input size={"large"} className={"rounded"} placeholder={t("label.password") ?? ""}
                                   type={"password"}
                                   value={passwordInput}
                                   onChange={e => setPasswordInput(e.target.value)}
                                   maxLength={40} />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label={t("label.confirmPassword")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.newPasswordConfirmation") }) as string,
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value || passwordInput === value) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error(t("notification.error.passwordNotMatch") as string))
                                    },
                                }),
                            ]}
                        >
                            <Input size={"large"} className={"rounded"} placeholder={t("label.password") ?? ""}
                                   type={"password"}
                                   value={passwordConfirmInput}
                                   onChange={e => setPasswordConfirmInput(e.target.value)}
                                   maxLength={40} />
                        </Form.Item>
                        <Form.Item className={"mb-4"}>
                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                                className={"bg-primary text-white !rounded-3xl w-full"}
                                size={"large"}
                                loading={resetPassword.isLoading}
                                disabled={!passwordInput || !passwordConfirmInput}
                            >
                                {t("action.login")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Login