import axios from 'axios';
import { useState } from 'react';

const usePayment = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const createPayment = async (itemId: string, startDate: Date, endDate: Date) => {
        setLoading(true);
        setError(null);
        try {
            // first start the lending process
            const lendingResponse = await axios.post(`/item/lend-item/${itemId}`, {
                planned_duration_begin: startDate,
                planned_duration_end: endDate,
            });

            // if lending was successful, proceed with payment
            if (lendingResponse.status === 200) {
                const response = await axios.post('/create-payment', {
                    item_id: itemId,
                    duration: Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)),
                    planned_duration_begin: startDate,
                    planned_duration_end: endDate,
                });

                const approval_link = response.data.approval_link;
                window.location.href = approval_link;
            } else {
                throw new Error(lendingResponse.data.error);
            }
        } catch (error: any) {
            console.error('Error during payment or lending creation', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const executePayment = async (paymentId: string) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post('/execute-payment', {
                payment_id: paymentId
            });
            return Promise.resolve(response); // Payment was successful
        } catch (error: any) {
            console.error('Error during payment execution', error);
            setError(error);
            return Promise.reject(error); // Payment resulted in error
        } finally {
            setLoading(false);
        }
    };

    return { createPayment, executePayment, loading, error };
};

export default usePayment;
