import { useQuery } from "react-query"
import { queryKey } from "../utils/consts"
import axios from "axios"
import { Item } from "./useItems"



const useSingleItem = (itemId: string) => {
    return useQuery([queryKey.singleItem, itemId], async (): Promise<Item> => {
        const { data } = await axios.get(`/item/single-item/${itemId}`)
        return data
    })
}

export default useSingleItem