import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Button, Input, Tag } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

const priceRanges = [
    {
        min: null,
        max: 300,
    },
    {
        min: 300,
        max: 500,
    },
    {
        min: 500,
        max: 1000,
    },
    {
        min: 1000,
        max: 2000,
    },
    {
        min: 2000,
        max: 5000,
    },
    {
        min: 5000,
        max: null,
    },
]

const parseIntOrNull = (input: string | null): number | null => {
    if (!input) {
        return null
    } else {
        return parseInt(input)
    }
}

const PriceFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedMinPrice: string | null = searchParams.get("minPrice")
    const selectedMaxPrice: string | null = searchParams.get("maxPrice")
    const [minPriceInput, setMinPriceInput] = useState<number | null>(null)
    const [maxPriceInput, setMaxPriceInput] = useState<number | null>(null)
    const { t } = useTranslation()

    const getMinAndMaxLabel = (minPrice: number | null, maxPrice: number | null): string => {
        if (!minPrice && maxPrice) {
            return t("label.upTo") + " " + maxPrice / 100 + " €/" + t("label.day")
        } else if (!maxPrice && minPrice) {
            return minPrice / 100 + "€/" + t("label.day") + " " + t("label.andAbove")
        } else if (minPrice && maxPrice && minPrice >= maxPrice) {
            return t("label.upTo") + " " + minPrice / 100 + " €/" + t("label.day")
        } else if (minPrice && maxPrice) {
            return minPrice / 100 + "€/" + t("label.day") + " " + t("label.to") + " " + maxPrice / 100 + " €/" + t("label.day")
        } else {
            return "Error"
        }
    }

    const setMinAndMaxPrice = (minPrice: number | null, maxPrice: number | null) => {
        // Set upper limit to 1000000
        if (minPrice && minPrice > 100000000)
            minPrice = 100000000
        if (maxPrice && maxPrice > 100000000)
            maxPrice = 100000000

        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("page", "1")
        if (!minPrice && maxPrice) {
            newSearchParams.set("maxPrice", maxPrice.toString())
            newSearchParams.delete("minPrice")
        } else if (!maxPrice && minPrice) {
            newSearchParams.set("minPrice", minPrice.toString())
            newSearchParams.delete("maxPrice")
        } else if (minPrice && maxPrice && minPrice >= maxPrice) {
            newSearchParams.set("maxPrice", minPrice.toString())
            newSearchParams.delete("minPrice")
        } else if (minPrice && maxPrice) {
            newSearchParams.set("minPrice", minPrice.toString())
            newSearchParams.set("maxPrice", maxPrice.toString())
        }
        setSearchParams(newSearchParams)
    }

    const removeMinAndMaxPrice = () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete("minPrice")
        newSearchParams.delete("maxPrice")
        setMinPriceInput(null)
        setMaxPriceInput(null)
        setSearchParams(newSearchParams)
    }

    return (
        <details open>
            <summary className="font-semibold mb-2">
                {t("label.price")}
            </summary>
            {selectedMinPrice || selectedMaxPrice
                ? <div>
                    <Tag className="flex justify-between w-full py-0.5" onClick={removeMinAndMaxPrice}>
                        <div className="text-darkgray">
                            {getMinAndMaxLabel(parseIntOrNull(selectedMinPrice), parseIntOrNull(selectedMaxPrice))}
                        </div>
                        <CloseOutlined />
                    </Tag>
                </div>
                : <div>
                    {priceRanges.map(price => (
                        <div onClick={() => setMinAndMaxPrice(price.min, price.max)}
                             className="block py-0.5 text-sm pl-1 hover:bg-gray-100 whitespace-nowrap">
                            {getMinAndMaxLabel(price.min, price.max)}
                        </div>
                    ))}
                    <div className={"flex justify-between pt-2"}>
                        <Input placeholder={"Min"}
                               className={"w-24"}
                               addonAfter="€"
                               onChange={(e) => {
                                   if (e.target.value.length == 0) {
                                       setMinPriceInput(null)
                                   } else {
                                       setMinPriceInput(parseInt(e.target.value) * 100)
                                   }
                               }}
                               onKeyPress={(e) => {
                                   if (!/[0-9]/.test(e.key)) {
                                       e.preventDefault()
                                   }
                               }}
                               onPressEnter={() => setMinAndMaxPrice(minPriceInput, maxPriceInput)}
                        />
                        <Input placeholder={"Max"}
                               className={"w-24"}
                               addonAfter="€"
                               onChange={(e) => {
                                   if (e.target.value.length == 0) {
                                       setMaxPriceInput(null)
                                   } else {
                                       setMaxPriceInput(parseInt(e.target.value) * 100)
                                   }
                               }}
                               onKeyPress={(e) => {
                                   if (!/[0-9]/.test(e.key)) {
                                       e.preventDefault()
                                   }
                               }}
                               onPressEnter={() => setMinAndMaxPrice(minPriceInput, maxPriceInput)}
                        />
                        <Button className={"w-14"}
                                onClick={() => setMinAndMaxPrice(minPriceInput, maxPriceInput)}
                        >
                            Go
                        </Button>
                    </div>
                </div>
            }
        </details>
    )
}

export default PriceFilter
