import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"
import { queryKey } from "../../utils/consts"
import { User } from "./useUser"
import axios from "axios"

async function signUp(email: string, paypalEmail: string, telephone: string, firstName: string, lastName: string, dateOfBirth: Date | null, password: string, street: string, zipCode: string, city: string): Promise<User> {
    return await axios.post("/auth/signup",
        { email, paypalEmail, telephone, firstName, lastName, dateOfBirth, password, street, zipCode, city },
        {
            headers: { "Content-Type": "application/json" },
        })
}

type IUseSignUp = UseMutationResult<User, unknown, {
    email: string;
    paypalEmail: string;
    telephone: string;
    firstName: string;
    lastName: string;
    dateOfBirth: Date | null;
    password: string;
    street: string;
    zipCode: string;
    city: string;
}>

export function useSignUp(): IUseSignUp {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const notification = useNotification()
    const { t } = useTranslation()

    return useMutation<User, unknown, {
        email: string,
        paypalEmail: string,
        telephone: string,
        firstName: string,
        lastName: string,
        dateOfBirth: Date | null,
        password: string,
        street: string,
        zipCode: string,
        city: string
    }, unknown>(
        ({
             email,
             paypalEmail,
             telephone,
             firstName,
             lastName,
             dateOfBirth,
             password,
             street,
             zipCode,
             city,
         }) => signUp(email, paypalEmail, telephone, firstName, lastName, dateOfBirth, password, street, zipCode, city), {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKey.user])
                notification("success", t("notification.success.signup.title"), t("notification.success.signup.message"))
                navigate("/login")
            },
            onError: (error) => {

                // @ts-ignore
                if (error.response.status === 409) {
                    return notification("error", t("notification.error.signup.duplicate-email.title"), t("notification.error.signup.duplicate-email.message"))
                }

                // @ts-ignore
                if (error.response.data.error == "InvalidAddress") {
                    return notification("error", "Invalid address", "Please provide a valid address in germany.")
                }

                return notification("error", t("notification.error.signup.title"), t("notification.error.signup.message"))
            },
        })
}

export default useSignUp