import { CheckCircleFilled, CloseCircleFilled, IdcardOutlined, MailOutlined } from "@ant-design/icons"
import { Button, Divider } from "antd"
import React, { useState } from "react"
import useSendVerificationMail from "../../hooks/auth/useSendVerificationMail"
import { useUser } from "../../hooks/auth/useUser"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "react-query"
import axios from "axios"
import useStripe from "../../hooks/useStripe"
import useNotification from "../../hooks/useNotification"
import { queryKey } from "../../utils/consts"

const VerificationTab = () => {

    const sendVerificationMail = useSendVerificationMail()
    const { idVerified, mailVerified, user } = useUser()
    const notification = useNotification();
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation()

    const stripe = useStripe()
    const queryClient = useQueryClient()


    const onResendMail = () => {
        sendVerificationMail.mutate({
            email: user?.email,
        })
    }
    const identify = useMutation(
        async () => {
            const response = await axios.get('/verify-id');
            return response.data;
        },
        {
            onSuccess: async (data) => {
                setIsLoading(true)
                const result = await stripe?.verifyIdentity(data.client_secret)
                if (result?.error) {
                    notification("error", t("notification.error.stripe.title"), t("notification.error.stripe.message"))
                } else {
                    notification("info", t("notification.info.stripe.title"), t("notification.info.stripe.message"))
                }
                await queryClient.invalidateQueries([queryKey.user])
                setIsLoading(false)
            },
        }
    );


    if (mailVerified === "verified" && idVerified === "verified") {
        return (
            <>
                <CheckCircleFilled className="text-primary text-4xl pb-5" />
                <div>{t("label.accountIsVerified")}</div>
            </>
        )
    }
    return (
        <>
            {mailVerified !== "verified" && <>
                <div className={"flex items-center mb-5"}>
                    <div className="relative w-12">
                        <MailOutlined className="w-full h-full absolute text-grayedOut text-4xl"/>
                        <CloseCircleFilled className="text-xl absolute top-1/2 left-1/2 transform -translate-x-[-4px] -translate-y-[-3px] text-red-600 bg-white rounded-full"/>
                    </div>
                    <div className="ml-5 w-full">
                        <p>{t("label.profile.mail")}</p>
                        <Button className="mt-2" onClick={onResendMail}>
                            {t("action.resendMail")}
                        </Button>
                    </div>
                </div>
            </>}
            {mailVerified !== "verified" && idVerified !== "verified" && <Divider />}
            {idVerified !== "verified" && <>
                <div className={"flex items-center mb-5"}>
                    <div className="relative w-12">
                        <IdcardOutlined className="w-full h-full absolute text-grayedOut text-4xl"/>
                        <CloseCircleFilled className="text-xl absolute top-1/2 left-1/2 transform -translate-x-[-4px] -translate-y-[-3px] text-red-600 bg-white rounded-full"/>
                    </div>
                    <div className="ml-5 w-full">
                        <p>{t("label.profile.identity")}</p>
                        <Button className={"mt-2"} onClick={() => identify.mutate()} loading={isLoading || idVerified === "processing"}>
                            {t("action.identify")}
                        </Button>
                    </div>
                </div>
            </>}
        </>
    )
}

export default VerificationTab