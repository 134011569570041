import { User, useUser } from "../../hooks/auth/useUser"
import AvatarUpload from "./Fields/AvatarUpload"
import React from "react"
import { Button, List, Modal } from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"
import useDeleteUser from "../../hooks/auth/useDeleteUser"
import FirstName from "./Fields/FirstName"
import LastName from "./Fields/LastName"
import DateOfBirth from "./Fields/DateOfBirth"
import Address from "./Fields/Address"
import PaypalEmail from "./Fields/PaypalEmail"
import Telephone from "./Fields/Telephone"
import { useTranslation } from "react-i18next"

const { confirm } = Modal

const GeneralTab = () => {
    const { user } = useUser()
    const deleteUser = useDeleteUser()
    const { t } = useTranslation()

    const settingsData = (user: User | null) => {

        return (
            [
                {
                    title: t("label.avatar"),
                    description: <AvatarUpload />,
                },
                {
                    title: t("label.mail"),
                    description: <div className={"pl-2 py-2"}>{user?.email}</div>,
                },
                {
                    title: t("label.firstName"),
                    description: <FirstName firstName={user?.firstName || ""} />,
                },
                {
                    title: t("label.lastName"),
                    description: <LastName lastName={user?.lastName || ""} />,
                },
                {
                    title: t("label.dateOfBirth"),
                    description: <DateOfBirth dateOfBirth={user?.dateOfBirth || new Date()} />,
                },
                {
                    title: t("label.address"),
                    description: <Address street={user?.street || ""} zipCode={user?.zipCode || ""}
                                          city={user?.city || ""} />,
                },
                {
                    title: t("label.paypalAddress"),
                    description: <PaypalEmail paypalEmail={user?.paypalEmail || ""} />,
                },
                {
                    title: t("label.telephone"),
                    description: <Telephone telephone={user?.telephone || ""} />,
                },
                {
                    title: "ID",
                    description: user?._id,
                },
            ])
    }

    const showDeleteAccountConfirm = () => {
        confirm({
            title: "Confirm account deletion",
            icon: <ExclamationCircleFilled />,
            content: "Are you sure you want to delete your account?",
            okText: "Delete account",
            okType: "danger",
            cancelText: t("action.cancel"),
            onOk() {
                deleteUser.mutate()
            },
        })
    }

    return (
        <>
            <List
                itemLayout="horizontal"
                dataSource={settingsData(user)}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={<a>{item.title}</a>}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
            <Button danger onClick={showDeleteAccountConfirm}>Delete account</Button>
        </>

    )
}


export default GeneralTab