import { useQuery } from "react-query"
import axios from "axios"
import {queryKey} from "../utils/consts";

async function getCategories():Promise<string[]>{
    const { data } = await axios.get(`/category`)
    return data
}



export function useCategories() {
    return useQuery([queryKey.categories], getCategories, {
        staleTime: Infinity
    })
}
