import useNotification from "../useNotification"
import { useMutation } from "react-query"
import axios from "axios"
import { queryClient } from "../../App"
import { queryKey } from "../../utils/consts"

const useDeleteUser = () => {

    const notifications = useNotification()

    return useMutation(() => axios.post("/auth/delete"), {
        onError: () => {
            notifications("error", "Failed deleting account", "")
        },
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey.user])
            notifications("success", "Account successfully deleted", "We hope we see you soon.")
        },
    })
}

export default useDeleteUser