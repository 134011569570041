import { Item } from "../hooks/useItems"
import { Buffer } from "buffer"

export type AtLeastOne<T, U = {[K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

export function formatCurrency(amount: number | undefined): string {
    if (amount == null) return ""
    const amountString = amount.toString();
    if (amountString.length <= 2) return "0." + amountString
    if (amountString.length <= 1) return "0.0" + amountString
    const wholePart = amountString.slice(0, -2);
    const decimalPart = amountString.slice(-2);

    return `${wholePart}.${decimalPart} €`;
}

export const toUTCDate = (dateStr: string) => {
    const date = new Date(dateStr)
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

export type ItemStatusType = "returned" | "inLending" | "pickUp" | "borrowable" | "exceeded"
export const getItemStatus = (item: Item): ItemStatusType => {
    if (item?.actual_duration_end) {
        return "returned"
    }
    let now = new Date();
    let end = new Date(item.planned_duration_end ?? Infinity);
    now.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    if (now.getTime() > end.getTime()) {
        return "exceeded";
    }
    if (item?.actual_duration_begin) {
        return "inLending"
    }
    if (item?.borrower) {
        return "pickUp"
    }
    return "borrowable"
}

export const toLocalDate = (dateStr: string, hour12: boolean, withTime = true) => {
    const date = new Date(dateStr);
    if (withTime) return date.toLocaleString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12 });
    return date.toLocaleString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
}

export const getBase64FromBuffer = (buffer: Buffer | undefined) => {
    if (buffer) {
        global.Buffer = global.Buffer || require("buffer").Buffer
        return Buffer.from(buffer).toString()
    } else {
        return undefined
    }
}

export function getAge(birthDate: Date) {
    const today = new Date()
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}

export function emailValid(email: string): boolean {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex and return the result
    return emailRegex.test(email);
}

export function telephoneValid(telephone: string): boolean {
    // Regular expression for validating email format
    const telephoneRegex = /^\+[1-9][0-9]{9,14}$/;

    // Test the email against the regex and return the result
    return telephoneRegex.test(telephone);
}
