import { useMutation, useQueryClient } from "react-query"
import useNotification from "../useNotification"
import { queryKey } from "../../utils/consts"
import { User } from "./useUser"
import axios from "axios"

async function sendPasswordResetMail(email: string): Promise<any> {
    return await axios.post('/auth/send-reset-password-mail',
    { email },
    { headers: { 'Content-Type': 'application/json' }
    }).catch(reason => {
         throw reason.response.data.error
        }
    )
}


export function useForgotPassword() {
    const queryClient = useQueryClient()
    const notification = useNotification()

    return useMutation<User, unknown, { email: string }>(
        ({
             email
         }) => sendPasswordResetMail(email), {
            onSuccess: (data:any) => {
                queryClient.setQueryData([queryKey.passwordResetMail], data);
                notification("success", "Password reset mail sent", "Successfully sent password reset mail. Check your inbox.")
            },
            onError: () => {
                notification("error", "Failed sending password reset mail", "Did you type in the right email?")
            }
        })
}

export default useForgotPassword