import React from "react"
import { useSearchParams } from "react-router-dom"
import { Select } from "antd"
import { useTranslation } from "react-i18next"

const SortBy: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedSortBy = searchParams.get("sortBy")
    const { t } = useTranslation()

    const changeSortBy = (sortBy: string | null) => {
        const newSearchParams = new URLSearchParams(searchParams)
        if (sortBy) {
            newSearchParams.set("sortBy", sortBy)
        } else {
            newSearchParams.delete("sortBy")
        }
        setSearchParams(newSearchParams)
    }

    return (
        <Select
            className={"w-44"}
            onChange={changeSortBy}
            value={selectedSortBy}
            options={[
                { value: null, label: t("label.sort.null") },
                { value: "oldest", label: t("label.sort.oldest") },
                { value: "cheapest", label: t("label.sort.cheapest") },
                { value: "mostExpensive", label: t("label.sort.mostExpensive") },
            ]}
        />
    )
}

export default SortBy
