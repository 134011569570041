import MainLayout from "./MainLayout"
import { Card, Spin } from "antd"
import { useLocation, useSearchParams } from "react-router-dom"
import React from "react"
import axios from "axios"
import { useQuery } from "react-query"
import { queryKey } from "../utils/consts"
import { useTranslation } from "react-i18next"
import { formatCurrency, toLocalDate, toUTCDate } from "../utils/helpers"
import { Item } from "../hooks/useItems"

const PickupReturnItem = () => {

    const [searchParams] = useSearchParams()
    const token = searchParams.get("token")
    const location = useLocation()
    const { t } = useTranslation()

    const { isLoading, isError, data: item } = useQuery([queryKey.pickupReturn, token],
        async (): Promise<Item> => {
            const res = await axios.get(`/item${location.pathname}?token=${token}`)
            return res.data
        },
        {
            enabled: !!token,
            retry: 1,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    )

    const rentalPeriod = item?.planned_duration_end && item?.planned_duration_begin
        ? (toUTCDate(item.planned_duration_end).getTime() - toUTCDate(item.planned_duration_begin).getTime()) / (1000 * 60 * 60 * 24)
        : 0
    

    const isReturning = location.pathname === "/return-item" || location.pathname === "/return-item/"
    const endOfRent = toLocalDate(item?.planned_duration_end ?? "", true, false)
    return (
        <MainLayout>
            <div className={"flex flex-col items-center p-4"}>
                {isLoading ?
                    <div className="flex items-center justify-center mt-32">
                        <Spin size="large" />
                    </div>
                    :
                    <Card>
                        {!token || isError ?
                            <>
                                <h1 className={"text-2xl"}>{t("notification.error.didNotWork")}</h1>
                                <p>{isReturning ? t("notification.error.return") : t("notification.error.pickup")}</p>
                            </>
                            :
                            isReturning ?
                                <>
                                    <h1 className={"text-2xl mb-4"}>{(t("label.pickupReturn.return"))}</h1>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="pr-4 whitespace-nowrap">{t("label.rentalPeriod")}:</td>
                                            <td className="pr-10 whitespace-nowrap">
                                                {rentalPeriod === 0 ? "" : `${rentalPeriod} ${rentalPeriod > 1 ? t("label.days") : t("label.day")}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4 whitespace-nowrap font-semibold">{t("label.totalItemPrice")}:</td>
                                            <td className={"font-semibold"}>{rentalPeriod === 0 ? "" : formatCurrency((item?.daily_price ?? 0) * rentalPeriod)}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </>
                                :
                                <>
                                    <h1 className={"text-2xl"}>{(t("label.pickupReturn.pickup"))}</h1>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="pr-4 whitespace-nowrap">{t("label.dailyPrice")}:</td>
                                            <td className="pr-10 whitespace-nowrap">{formatCurrency(item?.daily_price)}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4 whitespace-nowrap">{t("label.rentalPeriod")}:</td>
                                            <td className="pr-10 whitespace-nowrap">
                                                {rentalPeriod === 0 ? "" : `${rentalPeriod} ${rentalPeriod > 1 ? t("label.days") : t("label.day")}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4 whitespace-nowrap">{t("label.myItem.endOfRent")}:</td>
                                            <td className={"text-grayedOut"}>{endOfRent}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </>
                        }
                    </Card>
                }
            </div>
        </MainLayout>
    )
}

export default PickupReturnItem