import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"
import { queryKey } from "../../utils/consts"
import axios from "axios"

async function changeAvatar(image: any): Promise<any> {
    return await axios.post('/profile/changeAvatar',
        { image }, {})
        .catch(reason => {
            throw reason.response.data.error
        }
    )
}

type IUseChangeAvatar = UseMutationResult<any, unknown, {image: any}, unknown>
export function useChangeAvatar(): IUseChangeAvatar {
    const queryClient = useQueryClient()
    const notification = useNotification()
    const { t } = useTranslation()

    return useMutation(
        ({
             image
         }) => changeAvatar(image), {
            onSuccess: (data:any) => {
                queryClient.setQueryData([queryKey.changeAvatar], data);
                notification("success", "Image uploaded", "Image uploaded successfully.")
            },
            onError: () => {
                notification("error", "Failed uploading image", "Please upload image again.")
            }
        })
}

export default useChangeAvatar