import React, { useEffect, useState, useMemo } from "react";
import { Button, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { formatCurrency, toLocalDate, toUTCDate } from "../../utils/helpers";
import MainLayout from "../MainLayout";
import usePayment from "../../hooks/payment/usePayment";
import { Item } from "../../hooks/useItems";

interface BorrowItemProps {
    item?: Item;
    planned_duration_begin: string;
    planned_duration_end: string;
}

const PaymentSuccess = () => {
    const { paymentId } = useParams();
    const { executePayment } = usePayment();
    const { t } = useTranslation();
    const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
    const executePaymentWrapper = async () => {
        try {
            await executePayment(paymentId ?? "");
            setPaymentSuccessful(true);
        } catch (error) {
            console.log(error);
        }
    };

    const [data, setData] = useState<BorrowItemProps | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`/payments/${paymentId}`);
            const fetchedData = {
                item: response.data.item,
                planned_duration_begin: response.data.planned_duration_begin,
                planned_duration_end: response.data.planned_duration_end
            };
            setData(fetchedData);
        };
        fetchData();
    }, [paymentId]);

    const calculateDateDifference = useMemo(() => {
        console.log(data?.planned_duration_begin)
        console.log(data?.planned_duration_end)
        if (data?.planned_duration_begin && data.planned_duration_end) {
            const diffInTime = toUTCDate(data.planned_duration_end).getTime() - toUTCDate(data.planned_duration_begin).getTime();
            return Math.floor(diffInTime / (1000 * 3600 * 24));
        }
        return 0;
    }, [data]);

    return (
        <MainLayout>
            <div className={"flex flex-col items-center p-4"}>
                {!data ?
                    <div className="flex items-center justify-center mt-32">
                        <Card>
                            <h1 className={"text-2xl"}>{t("notification.error.item")}</h1>
                        </Card>
                    </div>
                    :
                    <Card>
                        <div className="p-4">
                            {
                                isPaymentSuccessful ?
                                    <div>Your payment was successful! You will receive your pickup instructions via E-Mail. You will find the QR-code under the My Items section.</div>
                                    :
                                    <>
                                        <h2 className={"text-2xl mb-4"}>{`Payment Authorization Successful for Item: ${data.item?.name}`}</h2>
                                        <div className="flex justify-between">
                                            <span>Rental period:</span>
                                            <span>{`${toLocalDate(data.planned_duration_begin ?? "", false, false)} to ${toLocalDate(data.planned_duration_end ?? "", false, false)}`}</span>
                                        </div>
                                        <div className="flex justify-between">
                                            <span>Number of rental days:</span>
                                            <span>{calculateDateDifference}</span>
                                        </div>
                                        <div className="flex justify-between mb-4">
                                            <span>Total amount (to be deducted on Pickup):</span>
                                            <span>{formatCurrency(data.item?.planned_price)}</span>
                                        </div>
                                        <Button
                                            onClick={() => executePaymentWrapper()}
                                            type="primary"
                                            className={"bg-primary mb-4"}
                                        >
                                            Rent now!
                                        </Button>
                                        <p className="text-sm text-gray-600">By clicking on "Rent now!" you agree to the following terms: The amount will be deducted on pickup of the item. If you do not return the item on time or damaged, we reserve the right to retain at least a portion of the deposit to compensate for the damage/loss incurred.</p>
                                    </>
                            }
                        </div>
                    </Card>
                }
            </div>
        </MainLayout>
    );

};

export default PaymentSuccess;
