import { useMutation, useQueryClient } from "react-query"
import useNotification from "../useNotification"
import { User } from "./useUser"
import { queryKey } from "../../utils/consts"
import axios from "axios"
import { useNavigate } from "react-router-dom"

async function resetPassword(password: string, passwordConfirm: string, token: string | null): Promise<any> {
    return await axios.post("/auth/reset-password",
        { token, password, passwordConfirm },
        {
            headers: { "Content-Type": "application/json" },
        }).catch(reason => {
            throw reason.response.data.error
        },
    )
}

export function useResetPassword() {
    const queryClient = useQueryClient()
    const notification = useNotification()
    const navigate = useNavigate();

    return useMutation<User, unknown, { password: string, passwordConfirm: string, token: string | null }>(
        ({
             password, passwordConfirm, token
         }) => resetPassword(password, passwordConfirm, token), {
            onSuccess: (data: any) => {
                queryClient.setQueryData([queryKey.resetPassword], data)
                notification("success", "Password reset successful", "You can log in again now")
                navigate("/")
            },
            onError: error => {
                if(error == "TokenExpired") {
                    return notification("info", "Expired token", "Please send activation mail again.")
                }
                notification("error", "Failed resetting password", "Try again")
            },
        })
}

export default useResetPassword