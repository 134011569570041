import React, { FC, useEffect, useState } from "react"
import { Button } from "antd"
import Timeline from "./Timeline"
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    QrcodeOutlined,
    UpOutlined,
} from "@ant-design/icons"
import { formatCurrency, getItemStatus, toLocalDate, toUTCDate } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import { Item } from "../../hooks/useItems"
import UpdateItem from "../ChangeItem/UpdateItem"
import ModalQRCode from "./ModalQRCode"
import ReportIncidentModal from "./ReportIncidentModal"

interface ItemInterface {
    defaultOpened?: boolean
    itemData: Item
    isLender: boolean
}

const ItemComponent: FC<ItemInterface> = ({ defaultOpened, itemData, isLender }) => {
    const [isExpanded, setExpanded] = useState(false)
    const [isUpdateItemOpen, setIsUpdateItemOpen] = useState(false)
    const [isQrCodeOpen, setIsQrCodeOpen] = useState(false)
    const [isIncidentOpen, setIsIncidentOpen] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        if (defaultOpened) {
            setExpanded(true)
        }
    }, [defaultOpened])

    const handleOpenItem = (event: any) => {
        if (event.key === "Enter" || event.key === " ") {
            !isExpanded && toggleExpanded()
        }
    }

    const scanQrCode = () => {
        setIsQrCodeOpen(true)
    }

    const itemStatus = getItemStatus(itemData)

    const toggleExpanded = () => setExpanded(!isExpanded)

    const rentalPeriod = itemData?.planned_duration_end && itemData?.planned_duration_begin
        ? (toUTCDate(itemData.planned_duration_end).getTime() - toUTCDate(itemData.planned_duration_begin).getTime()) / (1000 * 60 * 60 * 24)
        : 0

    const renderTable = (
        <table className={isExpanded ? "sm:border-r-[1px] sm:border-lightgray" : ""}>
            <tbody>
            <tr>
                <td className="pr-4 whitespace-nowrap">{t("label.rentalPeriod")}:</td>
                <td className="pr-10 whitespace-nowrap">
                    {rentalPeriod === 0 ? "" : `${rentalPeriod} ${rentalPeriod > 1 ? t("label.days") : t("label.day")}`}
                </td>
            </tr>
            <tr>
                <td className="pr-4 whitespace-nowrap">{t("label.dailyPrice")}:</td>
                <td className="pr-10 whitespace-nowrap">{formatCurrency(itemData?.daily_price)}</td>
            </tr>
            <tr>
                <td className="pr-4 whitespace-nowrap text-grayedOut">{t("label.securityDeposit")}:</td>
                <td className={"text-grayedOut"}>{rentalPeriod === 0 ? "" : formatCurrency(itemData.deposit)}</td>
            </tr>
            <tr>
                <td className="pr-4 whitespace-nowrap font-semibold">{t("label.totalItemPrice")}:</td>
                <td className={"font-semibold"}>{rentalPeriod === 0 ? "" : formatCurrency(itemData.daily_price * rentalPeriod)}</td>
            </tr>
            </tbody>
        </table>
    )
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    const planned = new Date(itemData?.planned_duration_begin ?? "")
    planned.setHours(0, 0, 0, 0)

    const renderButtons = (
        isExpanded && (
            <div className={"flex"}>
                {((itemStatus === "pickUp" && !isLender) || ((itemStatus === "inLending" || itemStatus === "exceeded") && isLender)) && planned <= now &&
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => scanQrCode()}
                            icon={<QrcodeOutlined />}
                            title={(isLender ? t("action.return") : t("action.pickUp")) ?? ""}>
                        {isLender ? t("action.return") : t("action.pickUp")}
                    </Button>}
                {itemStatus !== "borrowable" &&
                    <Button type={"text"}
                            disabled={!(isLender && !itemData.flaggedLender || !isLender && !itemData.flaggedBorrower)}
                            className={isLender && !itemData.flaggedLender || !isLender && !itemData.flaggedBorrower ? "text-grayedOut hover:!text-amber-500" : "!text-red-500"}
                            onClick={() => setIsIncidentOpen(true)}
                            icon={<ExclamationCircleOutlined />}
                            title={t("action.reportIncident") ?? ""} />}
                {itemStatus === "borrowable" &&
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => setIsUpdateItemOpen(true)}
                            icon={<EditOutlined />}
                            title={t("action.edit") ?? ""} />}

                {itemStatus == "borrowable" &&
                    <Button type={"text"} className="text-grayedOut hover:!text-red-400"
                            onClick={toggleExpanded}
                            icon={<DeleteOutlined />}
                            title={t("action.delete") ?? ""} />}
                <Button type={"text"} className="text-grayedOut"
                        onClick={toggleExpanded}
                        icon={<UpOutlined />}
                        title={t("action.collapse") ?? ""} />
            </div>
        )
    )

    const renderImage = (
        <div
            className={`${isExpanded ? "w-12 h-12 min-w-[3rem] min-h-[3rem] sm:w-56 sm:h-44 sm:min-w-56 sm:min-h-44" : "w-12 h-12"} overflow-hidden relative`}>
            <img alt={"item"} src={`api/item/photo/${itemData.photo}`}
                 className="absolute inset-0 w-full h-full object-cover" />
        </div>
    )

    const getCurrentStatus = () => {
        const startOfRent = toLocalDate(itemData?.planned_duration_begin ?? "", true, false)
        const pickedUp = toLocalDate(itemData?.actual_duration_begin ?? "", true)
        const returned = toLocalDate(itemData?.actual_duration_end ?? "", true)
        const endOfRent = toLocalDate(itemData.planned_duration_end ?? "", true, false)
        switch (itemStatus) {
            case "pickUp":
                return `${t("label.myItem.startOfRent")}: ${startOfRent}`
            case "inLending":
                return `${t("label.myItem.pickedUp")}: ${pickedUp}`
            case "returned":
                return `${t("label.myItem.returned")}: ${returned}`
            case "exceeded":
                return `${t("label.myItem.endOfRent")}: ${endOfRent}`
            default:
                return ""
        }
    }

    return (
        <>
            {/* Desktop */}
            <div
                className={`hidden sm:flex items-center p-4 ${!isExpanded ? "cursor-pointer hover:bg-gray-100" : ""}`}
                onClick={() => !isExpanded && toggleExpanded()} tabIndex={0} onKeyDown={handleOpenItem}>
                {renderImage}
                <div className={"w-full ml-6"}>
                    <div className={"flex justify-between flex-grow"}>
                        <div>
                            <h2 className={"text-xl "}>{itemData.name}</h2>
                            <p className={"text-sm"}>{itemStatus === "returned" ? t("label.myItem.youWereThe") : t("label.myItem.youAreThe")}
                                {" "}
                                <span className={"font-semibold text-primary"}>
                                    {isLender ? t("label.lender") : t("label.borrower")}
                                </span>
                            </p>
                            {itemStatus !== "borrowable" && isExpanded && (isLender ?
                                <p>{t("label.cBorrower")}: {itemData?.borrower?.firstName} {itemData?.borrower?.lastName}</p>
                                :
                                <p>{t("label.cLender")}: {itemData.lender.firstName} {itemData.lender.lastName}</p>)
                            }
                        </div>
                        {!isExpanded && (
                            <div className={"flex flex-col items-end mt-2"}>
                                <p className={`text-sm ${itemStatus === "exceeded" ? "text-red-500" : "text-primary"} whitespace-nowrap ml-3`}>{t(`label.myItem.${itemStatus}`)}</p>
                                <p className={`text-sm whitespace-nowrap ml-3`}>{getCurrentStatus()}</p>
                            </div>
                        )}
                        {renderButtons}
                    </div>
                    {isExpanded && (
                        <div className={"flex justify-between flex-grow mt-2"}>
                            {renderTable}
                            <div className={"flex flex-grow"}>
                                <Timeline item={itemData} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* Mobile */}

            <div
                className={`sm:hidden flex flex-col py-4 ${!isExpanded ? "cursor-pointer hover:bg-gray-100" : ""}`}
                onClick={() => !isExpanded && toggleExpanded()} tabIndex={0} onKeyDown={handleOpenItem}>
                <div className={"w-full"}>
                    <div className={"flex justify-between flex-grow"}>
                        <div className={"flex"}>
                            <div className={`h-12 w-12 min-h-[3rem] min-w-[3rem] overflow-hidden relative mr-4`}>
                                {renderImage}
                            </div>
                            <div>
                                <h2 className={"text-base"}>{itemData.name}</h2>
                                <p className={"text-sm"}>{itemStatus === "returned" ? t("label.myItem.youWereThe") : t("label.myItem.youAreThe")}
                                    {" "}
                                    <span className={"font-semibold text-primary"}>
                                        {isLender ? t("label.lender") : t("label.borrower")}
                                    </span>
                                </p>
                            </div>
                        </div>
                        {!isExpanded && (
                            <p className={`text-sm ${itemStatus === "exceeded" ? "text-red-500" : "text-primary"} whitespace-nowrap ml-3`}>{t(`label.myItem.${itemStatus}`)}</p>
                        )}
                        {renderButtons}
                    </div>
                    {isExpanded && (
                        <div className={"mt-2"}>
                            {renderTable}
                            <div className={"flex flex-grow mt-5 mb-10 translate-x-[-1.5rem]"}>
                                <Timeline item={itemData} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <UpdateItem item={itemData} open={isUpdateItemOpen} onClose={() => setIsUpdateItemOpen(false)} />
            <ModalQRCode open={isQrCodeOpen} isLender={isLender} item={itemData}
                         onCancel={() => setIsQrCodeOpen(false)} />
            <ReportIncidentModal open={isIncidentOpen} onCancel={() => setIsIncidentOpen(false)} item={itemData}
                                 isLender={isLender} />
        </>
    )
}

export default ItemComponent