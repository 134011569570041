import { useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "react-query"
import { queryKey } from "../../utils/consts"
import axios from "axios"


export function useSignOut() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    return useMutation(() => axios.post("/auth/logout"), {
        onSuccess: () => {
            queryClient.removeQueries([queryKey.user])
            queryClient.invalidateQueries([queryKey.user]).then(() => navigate("/"))
        },
    })
}