import React from "react"
import { Route, Routes } from "react-router-dom"
import Notice from "./components/Notice"
import { ConfigProvider } from "antd"
import Login from "./components/Auth/Login"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import Browse from "./components/Browse/Browse"
import SignUp from "./components/Auth/SignUp"
import MyItems from "./components/MyItems/MyItems"
import Profile from "./components/Profile/Profile"
import VerifyAccount from "./components/Auth/VerifyAccount"
import ForgotPassword from "./components/Auth/ForgotPassword"
import ItemDetails from "./components/Browse/ItemDetails"
import Reviews from "./components/Reviews/Reviews"
import OfferItem from "./components/ChangeItem/OfferItem"
import ResetPassword from "./components/Auth/ResetPassword"
import ProtectedRoute from "./components/ProtectedRoute"
import NotFoundPage from "./components/NotFoundPage"
import "./setupAxios"
import AdminRoute from "./components/AdminRoute"
import AdminPanel from "./components/Admin/AdminPanel"
import PaymentSuccess from "./components/Payment/ExecutePayment"
import HomePage from "./components/HomePage/HomePage"
import PickupReturnItem from "./components/PickupReturnItem"
import VerifiedRoute from "./components/VerifiedRoute"
import FAQ from "./components/FAQ"
import PaymentFailed from "./components/Payment/FailedPayment"

export const queryClient = new QueryClient()

function App() {

    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#11C9AA",
                    },
                }}
            >
                <div className="flex flex-col">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="notice" element={<Notice />} />
                        <Route path="login" element={<Login />} />
                        <Route path="browse" element={<Browse />} />
                        <Route path="sign-up" element={<SignUp />} />
                        <Route path="item-details" element={<ItemDetails />} />
                        <Route path="login" element={<Login />} />
                        <Route path="sign-up" element={<SignUp />} />
                        <Route path="reviews" element={<Reviews />} />
                        <Route path="payment-failure" element={<PaymentFailed />} />
                        <Route path="password-forget" element={<ForgotPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="verify-account" element={<VerifyAccount />} />
                        <Route path="payment-success/:paymentId" element={<PaymentSuccess />} />
                        <Route path="faq" element={<FAQ />} />
                        <Route element={<ProtectedRoute />}>
                            <Route element={<VerifiedRoute />}>
                                <Route path="offer-item" element={<OfferItem />} />
                            </Route>
                            <Route path="my-items" element={<MyItems />} />
                            <Route path="profile/*" element={<Profile />} />
                            <Route path="pickup-item" element={<PickupReturnItem />} />
                            <Route path="return-item" element={<PickupReturnItem />} />
                        </Route>
                        <Route element={<AdminRoute />}>
                            <Route path="admin" element={<AdminPanel />} />
                        </Route>
                        <Route path={"*"} element={<NotFoundPage />} />
                    </Routes>
                </div>
            </ConfigProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )

}

export default App
