import { Outlet } from "react-router-dom";
import React from "react";
import { useUser } from "../hooks/auth/useUser"
import { Spin } from "antd"
import MainLayout from "./MainLayout"
import NotFoundPage from "./NotFoundPage"

const AdminRoute = () => {
    const { user, isLoading } = useUser();

    if (isLoading) {
        return (
            <MainLayout>
                <div className="flex items-center justify-center h-screen">
                    <Spin size="large" />
                </div>
            </MainLayout>
        )
    }
    return user?.isAdmin ? (
        <>
            <Outlet />
        </>
    ) : (
        <NotFoundPage />
    );
};

export default AdminRoute;