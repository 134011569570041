import React from "react"
import { useSearchParams } from "react-router-dom"
import { DatePicker, Tag } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import type { Dayjs } from "dayjs"

const { RangePicker } = DatePicker

const disabledDate = (current: Dayjs): boolean => {
    const currentDate = new Date()
    return current.diff(currentDate, "days") < 0
}

const displayDateRange = (startDate: string, endDate: string) => {
    return startDate + " - " + endDate
}

const RentingDateFilter: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedStartDate: string | null = searchParams.get("availability_start")
    const selectedEndDate: string | null = searchParams.get("availability_end")

    const setStartAndEndDate = (startDate: string, endDate: string) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("page","1")
        newSearchParams.set("availability_start", startDate)
        newSearchParams.set("availability_end", endDate)
        setSearchParams(newSearchParams)
    }

    const removeStartAndEndDate = () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete("availability_start")
        newSearchParams.delete("availability_end")
        setSearchParams(newSearchParams)
    }

    return (
        <details open>
            <summary className="font-semibold mb-2">
                Renting Date
            </summary>
            {selectedStartDate && selectedEndDate
                ? <div>
                    <Tag className="flex justify-between w-full py-0.5" onClick={removeStartAndEndDate}>
                        <div className="text-darkgray">
                            {displayDateRange(selectedStartDate, selectedEndDate)}
                        </div>
                        <CloseOutlined />
                    </Tag>
                </div>
                : <div>
                    <RangePicker
                        onChange={(value, dateStrings) => {
                            setStartAndEndDate(dateStrings[0], dateStrings[1])
                        }}
                        disabledDate={disabledDate} allowClear={false}
                    />
                </div>
            }
        </details>
    )
}

export default RentingDateFilter
