import React, { useEffect, useState } from "react"
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { Upload } from "antd"
import type { UploadChangeParam } from "antd/es/upload"
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface"
import useNotification from "../../../hooks/useNotification"
import { useUser } from "../../../hooks/auth/useUser"
import useDeleteAvatar from "../../../hooks/profile/useDeleteAvatar"
import useChangeAvatar from "../../../hooks/profile/useChangeAvatar"
import { getBase64FromBuffer } from "../../../utils/helpers"
import { useTranslation } from "react-i18next"

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => callback(reader.result as string))
    reader.readAsDataURL(img)
}

const AvatarUpload = () => {
    const notifications = useNotification()
    const [loading] = useState(false)
    const [image, setImage] = useState<string>()
    const changeAvatar = useChangeAvatar()
    const deleteAvatar = useDeleteAvatar()
    const { user } = useUser()
    const { t } = useTranslation()

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
        if (!isJpgOrPng) {
            notifications("error", t("notification.error.wrongFileFormat.title"), t("notification.error.wrongFileFormat.message"))
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            notifications("error", t("notification.error.imageTooBig.title"), t("notification.error.imageTooBig.message"))
        }
        return isJpgOrPng && isLt2M
    }

    const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === "done") {
            notifications("success", t("notification.success.uploadPic.title"), t("notification.success.uploadPic.message"))
        }
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div>Upload<br />avatar</div>
        </div>
    )

    const handleAvatarUpload = ({ file }: { file: any }) => {
        getBase64(file as RcFile, (url) => {
            changeAvatar.mutate({
                image: url,
            })
            while (changeAvatar.isLoading) {
            }
            if (!changeAvatar.isError) {
                setImage(url)
            }
        })
    }

    const handleDelete = () => {
        deleteAvatar.mutate()
        while (deleteAvatar.isLoading) {
        }
        if (!deleteAvatar.isError) {
            setImage(undefined)
        }
    }

    useEffect(() => {
        if (user?.avatar)
            setImage(getBase64FromBuffer(user?.avatar.data))
    }, [user])

    return (
        <div>
            <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader pt-2"
                showUploadList={false}
                customRequest={handleAvatarUpload}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {image
                    ?
                    <img src={image}
                         alt="avatar"
                         className={"object-cover w-24 h-24 rounded-full"}>
                    </img>
                    : uploadButton}
            </Upload>
            {image &&
                <CloseCircleOutlined
                    className={"absolute top-10 z-50 left-20 text-2xl rounded-full"}
                    onClick={handleDelete} />
            }

        </div>
    )
}

export default AvatarUpload