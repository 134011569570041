import { useQuery } from "react-query"
import { queryKey } from "../utils/consts"
import axios from "axios"
import { Item } from "./useItems"

const useMyItems = () => {
    return useQuery([queryKey.myItems], async (): Promise<Item[]> => {
        const res = await axios.get("/item/my-items")
        return res.data
    })
}

export default useMyItems