import { Button } from "antd"
import {useTranslation} from "react-i18next"
import React, {useState} from "react"
import MainLayout from "../MainLayout"
import {useUser} from "../../hooks/auth/useUser"
import useItemMutations from "../../hooks/useItemMutations"
import "./ItemForm.css"
import ItemForm from "./ItemForm";
import {Item} from "../../hooks/useItems";
import {toUTCDate} from "../../utils/helpers";
import useNotification from "../../hooks/useNotification"



const OfferItem = () => {
    const {t} = useTranslation()
    const {user} = useUser()
    const {itemCreation, itemPhotoCreation} = useItemMutations()
    const today = toUTCDate(new Date().toISOString())
    const maxdate = new Date(new Date(today.getTime() + 28 * 24 * 60 * 60 * 1000).toISOString())
    const notification = useNotification()

    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [photo, setPhoto] = useState<Blob | null>(null)
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
    const [minimalRentingPeriod, setMinimalRentingPeriod] = useState<number>()
    const [dailyPrice, setDailyPrice] = useState<number>()
    const [category, setCategory] = useState<string>("")
    const [availabilityStart, setAvailabilityStart] = useState<Date>(today)
    const [availabilityEnd, setAvailabilityEnd] = useState<Date>(maxdate)

    const isFormValid = !!name && !!description && !!category && !!dailyPrice && !!availabilityStart && !!availabilityEnd
    const handleSubmit = async () => {
        // Formdata needed so axios can send binary
        let responsePhoto
        if (photo) {
            const formData = new FormData()
            formData.append("photo", photo)
            responsePhoto = await itemPhotoCreation.mutateAsync(formData)

            const newItem: Partial<Item> = {
                name: name ?? "",
                description: description ?? "",
                photo: responsePhoto?.data.photoId,
                minimal_renting_period: minimalRentingPeriod ?? 1,
                daily_price: dailyPrice ?? 0,
                category: category ?? "",
                availability_duration_begin: availabilityStart?.toISOString() ?? "",
                availability_duration_end: availabilityEnd?.toISOString() ?? "",
                street: user?.street ?? "",
                zip_code: user?.zipCode ?? "",
                city: user?.city ?? ""
            }
            itemCreation.mutate(newItem)
        } else {
            notification("error", t("notification.error.noPic.title"),t("notification.error.noPic.message"))
        }

    }


    return (
        <MainLayout>
            <div className={"flex flex-grow items-center justify-center"}>
                <div className="w-full sm:w-3/4 lg:w-2/4 mx-2 flex flex-col gap-4">
                    <h1 className={"p-4 text-2xl"}>
                        {t("label.createItem.headline")}
                    </h1>
                    <ItemForm name={name} onNameChange={setName}
                              description={description} onDescriptionChange={setDescription}
                              onPhotoChange={setPhoto} imageUrl={imageUrl} onImageUrlChange={setImageUrl}
                              dailyPrice={dailyPrice} ondailyPriceChange={setDailyPrice}
                              category={category} onCategoryChange={setCategory}
                              minimalRentingPeriod={minimalRentingPeriod} onMinimalRentingPeriodChange={setMinimalRentingPeriod}
                              availabilityStart={availabilityStart} onAvailabilityStartChange={setAvailabilityStart}
                              availabilityEnd={availabilityEnd}
                              onAvailabilityEndChange={setAvailabilityEnd}
                              isRequired={true}/>
                    <div className={"flex self-end"}>
                    <Button type="primary" className={"bg-primary"} onClick={handleSubmit} disabled={!isFormValid}>
                    {t("action.offer-item")}
                </Button>
            </div>
        </div>
</div>
</MainLayout>
)
}

export default OfferItem
