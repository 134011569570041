const Notice = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
            }}
        >
            <div>
                <h2>Legal Notice</h2>
                <h5>{"Angaben gemäß § 5 TMG"}</h5>
                <p>
                    Lendless <br />
                    Maximilian Brügge <br />
                    Daniel Wiedenmann <br />
                    Robert Frank <br />
                    Nils Römer <br />
                    <br />
                    Sandratstraße 25 <br />
                    80687 München
                </p>
            </div>
        </div>
    )
}

export default Notice
