import { Button, Dropdown, Input, MenuProps, Select } from "antd"
import {
    DollarOutlined,
    DownOutlined,
    FieldTimeOutlined, LogoutOutlined, MenuOutlined, QuestionCircleOutlined, SafetyCertificateOutlined, SearchOutlined,
    UserOutlined,
} from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import logo from "../assets/logo/logo.svg"
import deFlag from "../assets/flags/de.png"
import usFlag from "../assets/flags/us.png"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSignOut } from "../hooks/auth/useSignOut"
import UnverifiedNote from "./Auth/UnverifiedNote"
import { useUser } from "../hooks/auth/useUser"
import { useCategories } from "../hooks/useCategories"


const flagIcons: { [key: string]: any } = {
    en: <img src={usFlag} className="w-6 pl-1 min-w-[1.5rem]" alt="logo" />,
    de: <img src={deFlag} className="w-6 pl-1 min-w-[1.5rem]" alt="logo" />,
}

const { Option } = Select

const Navbar: React.FC = () => {
    const [searchParams] = useSearchParams()
    const search = searchParams.get("search")
    const { t, i18n } = useTranslation()
    const { isAuth, user } = useUser()
    const signOutUser = useSignOut()
    const [searchCategory, setSearchCategory] = useState<string>(searchParams.get("category") ?? "")
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [selectWidth, setSelectWidth] = useState<string>("")
    const navigate = useNavigate()
    const { data: categories } = useCategories()

    const makeSearch = (searchTerm: string | undefined, searchCategory: string | undefined) => {
        if (searchTerm !== undefined) {
            searchParams.set("search", searchTerm)
        }
        if (searchCategory) {
            searchParams.set("category", searchCategory)
        }
        navigate(`/browse${searchParams.toString() ? "?" + searchParams : ""}`)
    }

    useEffect(() => {
        setSearchTerm(search ?? "")
    }, [search])

    useEffect(() => {
        setSearchCategory(searchParams.get("category") ?? "")
    }, [searchParams])

    const selectCategory = (
        <Select
            size="large"
            onChange={(category) => {
                setSearchCategory(category)
            }}
            className={selectWidth}
            onDropdownVisibleChange={(vis) => {
                if (vis) {
                    setSelectWidth("w-32")
                } else {
                    setSelectWidth("")
                }
            }}
            value={searchCategory}
            defaultValue=""
        >
            <Option value="">All</Option>
            {categories?.map(cat => (
                <Option
                    value={cat}>{t(`categories.${cat}`)}</Option>
            ))}
        </Select>
    )


    const offerItem: MenuProps["items"] = [
        {
            key: "1",
            icon: <DollarOutlined />,
            label: <Link to={"/offer-item"}>{t("action.offer-item")}</Link>,
        },
    ]

    let userDropdownItems: MenuProps["items"] = [
        {
            key: "1",
            icon: <FieldTimeOutlined />,
            label: <Link to={"/my-items"}> {t("label.navbar.myItems")} </Link>,
        },
        {
            key: "2",
            icon: <UserOutlined />,
            label: <Link to={"/profile"}> {t("label.navbar.profile")} </Link>,
        },
        {
            key: "3",
            icon: <QuestionCircleOutlined />,
            label: <Link to={"/faq"}> {t("label.navbar.faq")} </Link>,
        },
        {
            key: "4",
            icon: <LogoutOutlined />,
            label: <p
                className={"inline-block"}
            >
                {t("action.logout")}
            </p>,
            onClick: () => signOutUser.mutate(),
        },
    ]
    if (user?.isAdmin) {
        userDropdownItems.push({
            key: "5",
            icon: <SafetyCertificateOutlined />,
            label: <Link to={"/admin"}> {t("label.navbar.adminPanel")} </Link>,
        },)
    }

    const languageDropdownItem: MenuProps["items"] = [
        {
            key: "de",
            icon: flagIcons.de,
            label: <p>Deutsch</p>,
        },
        {
            key: "en",
            icon: flagIcons.en,
            label: <p>English</p>,
        },
    ]

    const onLanguageChange = (e: any) => {
        if (e!.key) {
            i18n.changeLanguage(e.key)
        }
    }

    const showUserDropdown = (
        <Dropdown
            menu={{ items: userDropdownItems }}
            className={"flex mr-5 cursor-default"}
        >
            <p className={"whitespace-nowrap"}>{t("label.hello")} {user?.firstName}</p>
        </Dropdown>
    )

    const showLoginButton = (
        <Link to="/login"
              className="flex flex-nowrap mr transition-colors duration-300 ease-in-out hover:text-primary whitespace-nowrap">
            {t("action.login")}
        </Link>
    )

    return (
        <nav className="relative z-30">
            <UnverifiedNote />
            {/* mobile */}
            <div className="md:hidden p-4 bg-white flex flex-col gap-4 fixed w-full top-0 z-30">
                <div className="flex gap-3 w-full justify-between">
                    <Link to="/" className="flex flex-nowrap">
                        <img src={logo} className="w-40 min-w-[10rem] pl-1" alt="logo" />
                    </Link>
                    {isAuth ? (
                        <Dropdown
                            menu={{ items: offerItem.concat(userDropdownItems) }}
                            className="flex flex-nowrap mr-2"
                        >
                            <MenuOutlined className="m-0" />
                        </Dropdown>
                    ) : (
                        showLoginButton
                    )}
                </div>
                <Input addonBefore={<SearchOutlined />}
                       placeholder={t("placeholder.searchLendless") ?? ""}
                       className="bg-white rounded-md max-w-[50rem]"
                       value={searchTerm}
                       onChange={(e) => setSearchTerm(e.target.value)}
                       onPressEnter={() => makeSearch(searchTerm, undefined)}
                />
            </div>

            {/* desktop */}
            <div>
                <div className="hidden md:flex items-center justify-between flex-nowrap gap-14 p-4 h-16">
                    <Link to="/" className="flex flex-nowrap mr-5">
                        <img src={logo} className="w-40 min-w-[10rem] pl-1" alt="logo" />
                    </Link>
                    <div className="flex flex-nowrap flex-row flex-grow justify-center gap-1">
                        <Input addonBefore={selectCategory}
                               size="large"
                               placeholder={t("placeholder.searchLendless") ?? ""}
                               className="min-w-[5rem] max-w-[50rem]"
                               value={searchTerm}
                               onChange={(e) => setSearchTerm(e.target.value)}
                               onPressEnter={() => makeSearch(searchTerm, searchCategory)} />
                        <Dropdown
                            menu={{
                                items: languageDropdownItem.filter((v) => v?.key !== i18n.language),
                                onClick: onLanguageChange,
                            }}
                        >
                            <div className="flex items-center gap-1">
                                {flagIcons[i18n.language]}
                                <DownOutlined className="w-3" />
                            </div>
                        </Dropdown>
                    </div>
                    <div className="flex flex-nowrap gap-10 items-center mr-6">
                        <Link to={"/offer-item"}>
                            <Button type={"primary"}
                            className="text-white bg-primary inline-block">{t("action.offer-item")}</Button>
                        </Link>
                        {isAuth ? showUserDropdown : showLoginButton}
                    </div>
                </div>
            </div>
            <div
                className="flex overflow-x-auto hide-scrollbar border-y-[1px] border-lightgray px-5 mt-[6.6rem] md:mt-0">
                {categories?.map((cat, cnt) => (
                    <Link key={cnt} to={`/browse?category=${cat}`}
                          className="block px-4 py-3 text-darkgray text-sm hover:bg-gray-100 whitespace-nowrap">
                        {t(`categories.${cat}`)}
                    </Link>
                ))}
            </div>
        </nav>
    )
}

export default Navbar
