import {Button, Modal} from "antd"
import {useTranslation} from "react-i18next"
import React, {useState} from "react"
import {useUser} from "../../hooks/auth/useUser"
import "./ItemForm.css"
import ItemForm from "./ItemForm";
import {toUTCDate} from "../../utils/helpers";
import {Item} from "../../hooks/useItems";
import useItemMutations from "../../hooks/useItemMutations";

interface UpdateItemProps {
    item: Item
    open: boolean
    onClose(): void
}

const UpdateItem = ({item, open, onClose}: UpdateItemProps) => {
    const {t} = useTranslation()
    const {user} = useUser()
    const {itemMutation, itemPhotoCreation} = useItemMutations()

    const [name, setName] = useState<string>(item?.name)
    const [description, setDescription] = useState<string>(item?.description)
    const [imageUrl, setImageUrl] = useState<string>(`api/item/photo/${item?.photo}`)
    const [photo, setPhoto] = useState<Blob | undefined>(undefined)
    const [minimalRentingPeriod, setMinimalRentingPeriod] = useState<number>(item?.minimal_renting_period)
    const [dailyPrice, setDailyPrice] = useState<number>(item?.daily_price)
    const [category, setCategory] = useState<string>(item?.category)
    const [availabilityStart, setAvailabilityStart] = useState<Date>(toUTCDate(item.availability_duration_begin))
    const [availabilityEnd, setAvailabilityEnd] = useState<Date>(toUTCDate(item.availability_duration_end))

    const handleSubmit = async () => {
        // Formdata needed so axios can send binary
        let responsePhoto
        if (photo) {
            const formData = new FormData()
            formData.append("photo", photo)
            responsePhoto = await itemPhotoCreation.mutateAsync(formData)
        }

        const itemData: Partial<Item> = {
            name: name,
            description: description,
            photo: responsePhoto?.data.photoId ?? item.photo ,
            minimal_renting_period: minimalRentingPeriod,
            daily_price: dailyPrice,
            category: category,
            availability_duration_begin: availabilityStart?.toISOString(),
            availability_duration_end: availabilityEnd?.toISOString(),
            street: user?.street ?? "",
            zip_code: user?.zipCode ?? "",
            city: user?.city  ?? ""
        }
        itemMutation.mutate({itemData , itemId: item._id})
    }

    // useEffect(()=>{
    //     if(itemMutation.isSuccess){
    //         onClose()
    //     }
    // }, [itemMutation])

    return (
        <Modal
            title={t("label.updateItem.headline")}
            open={open}
            onCancel={() => onClose()}
            width={1000}
            footer={[
                <Button key="back" onClick={() => onClose()}>
                    Cancel
                </Button>,
                <Button type="primary" className={"bg-primary"} onClick={handleSubmit}>
                    {t("action.change-item")}
                </Button>
            ]}
        >
            <div className="flex flex-col gap-5">
                <ItemForm name={name ?? ""} onNameChange={setName}
                          description={description ?? ""} onDescriptionChange={setDescription}
                          onPhotoChange={setPhoto}
                          imageUrl={imageUrl} onImageUrlChange={setImageUrl}
                          dailyPrice={dailyPrice} ondailyPriceChange={setDailyPrice}
                          category={category ?? ""} onCategoryChange={setCategory}
                          minimalRentingPeriod={minimalRentingPeriod}
                          onMinimalRentingPeriodChange={setMinimalRentingPeriod}
                          availabilityStart={availabilityStart} onAvailabilityStartChange={setAvailabilityStart}
                          availabilityEnd={availabilityEnd}
                          onAvailabilityEndChange={setAvailabilityEnd}
                          isRequired={false}/>
            </div>
        </Modal>
    )
}

export default UpdateItem
