import React from "react"
import CategoriesFilter from "./Filter/CategoriesFilter"
import LocationFilter from "./Filter/LocationFilter"
import PriceFilter from "./Filter/PriceFilter"
import MinimalRentingPeriodFilter from "./Filter/MinimalRentingPeriodFilter"
import RentingDateFilter from "./Filter/RentingDateFilter"

const Filter: React.FC = () => {

    return (
        <div>
            <div className={"grid gap-4 m-3"}>
                <CategoriesFilter/>
                <LocationFilter/>
                <PriceFilter/>
                <RentingDateFilter/>
                <MinimalRentingPeriodFilter/>
            </div>
        </div>
    )
}

export default Filter;
