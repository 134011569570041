import { useUser } from "../../hooks/auth/useUser"
import { Card } from "antd"
import React, { ReactElement } from "react"
import { Badge } from "antd"
import MainLayout from "../MainLayout"
import GeneralTab from "./GeneralTab"
import VerificationTab from "./VerificationTab"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"


const tabContent: { [key: string]: ReactElement } = {
    general: <GeneralTab />,
    verification: <VerificationTab />,
}

const Profile = () => {
    const navigate = useNavigate();
    const routeLocation = useLocation();
    let activeTabKey = routeLocation.pathname.replace("/profile/", "")
    activeTabKey = activeTabKey === "" || activeTabKey === "/profile" ? "general" : activeTabKey
    const { t } = useTranslation()
    const {mailVerified, idVerified} = useUser()

    const tabList = [
        {
            key: "general",
            tab: t("label.profile.general"),
        },
        {
            key: "verification",
            tab: <>{t("label.profile.verification")}{(mailVerified !== "verified" || idVerified !== "verified") && <Badge className={"pb-4"} dot />}</>,
        },
    ]

    const onTabChange = (key: string) => {
        navigate(`/profile/${key}`)
    }

    return (
        <MainLayout>
            <div className="flex justify-around">
                <div className="grow p-5 max-w-screen-md top-5">
                    <Card
                        title={t("label.profile.profile")}
                        tabList={tabList}
                        activeTabKey={activeTabKey}
                        onTabChange={onTabChange}
                    >
                        {tabContent[activeTabKey ?? "general"]}
                    </Card>
                </div>
            </div>
        </MainLayout>
    )
}

export default Profile