import MainLayout from "./MainLayout"
import { Button } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
    const { t } = useTranslation()

    return (
        <MainLayout>
            <div className={"flex flex-col flex-grow items-center"}>
                <div className={"text-[10rem] mt-[10vh]"}>
                    404
                </div>
                <div className={"text-xl"}>
                    {t("label.notFound")}
                </div>
                <Link to={"/"}>
                    <Button type={"primary"} size={"large"} className="text-white bg-primary inline-block mt-8">Go
                        back</Button>
                </Link>
            </div>
        </MainLayout>
    )
}

export default NotFoundPage