import { useLocation, useNavigate } from "react-router-dom"
import axios, { AxiosError } from "axios"
import React from "react"
import { useQuery } from "react-query"
import useNotification from "../../hooks/useNotification"
import { useTranslation } from "react-i18next"

const verifyAccount = async (token: string | null) => {
    const res = await axios.post("/auth/verify-account", { token })
    return res.data
}

const VerifyAccount = () => {
    const { search } = useLocation()
    const parameters = new URLSearchParams(search)
    const notification = useNotification()
    const navigate = useNavigate()
    const token = parameters.get("token")
    const { t } = useTranslation()
    const {} = useQuery<{ message: string }, AxiosError<{
        error: string
    }>>("verifyAccount", () => verifyAccount(token), {
        onSuccess: () => {
            notification("success", t("notification.success.verifyMail.title"), t("notification.success.verifyMail.message"))
            navigate("/")
        },
        onError: error => {
            if (error.response?.data.error == "AccountAlreadyVerified") {
                notification("info", t("notification.info.verifyMail.title"), t("notification.info.verifyMail.message"))
                navigate("/")
            } else if (error.response?.data.error == "TokenExpired") {
                notification("error", t("notification.error.expiredToken.title"), t("notification.error.expiredToken.message"))
                navigate("/")
            } else {
                notification("error", t("notification.error.invalidToken.title"), t("notification.error.invalidToken.message"))
                navigate("/")
            }
        },
    })

    return (
        <div className={"flex justify-center"}>
            <h1>Verifying account</h1>
        </div>
    )
}

export default VerifyAccount