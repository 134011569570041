import logo from "../../assets/logo/logo.svg"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, DatePickerProps, Form, Input } from "antd"
import useSignUp from "../../hooks/auth/useSignUp"
import { Link } from "react-router-dom"
import { DatePicker } from "antd"
import useNotification from "../../hooks/useNotification"
import { Dayjs } from "dayjs"
import { getAge } from "../../utils/helpers"

const SignUp = () => {

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const signUpUser = useSignUp()
    const notification = useNotification()

    const [emailInput, setEmailInput] = useState<string>("")
    const [paypalEmailInput, setPaypalEmailInput] = useState<string>("")
    const [telephoneInput, setTelephoneInput] = useState<string>("")
    const [firstNameInput, setFirstNameInput] = useState<string>("")
    const [lastNameInput, setLastNameInput] = useState<string>("")
    const [dateOfBirthInput, setDateOfBirthInput] = useState<Dayjs | null>(null)
    const [passwordInput, setPasswordInput] = useState<string>("")

    const [streetInput, setStreetInput] = useState<string>("")
    const [zipCodeInput, setZipCodeInput] = useState<string>("")
    const [cityInput, setCityInput] = useState<string>("")

    const onFinish = () => {
        if (dateOfBirthInput && getAge(dateOfBirthInput?.toDate()) < 18) {
            notification("error", t("notification.error.signup.under-eighteen.title"), t("notification.error.signup.under-eighteen.message"))
        } else if (dateOfBirthInput) {
            const year = dateOfBirthInput.year()
            const month = dateOfBirthInput.month()
            const day = dateOfBirthInput.day() + 1

            signUpUser.mutate({
                email: emailInput,
                paypalEmail: paypalEmailInput,
                telephone: telephoneInput,
                firstName: firstNameInput,
                lastName: lastNameInput,
                dateOfBirth: new Date(Date.UTC(year, month, day)),
                password: passwordInput,
                street: streetInput,
                zipCode: zipCodeInput,
                city: cityInput,
            })
        }
    }

    const onChangeBirthDate: DatePickerProps["onChange"] = (date) => {
        setDateOfBirthInput(date)
    }

    // @ts-ignore
    return (
        <>
            <div className={"flex items-center justify-center flex-col my-16"}>
                <div className={"flex flex-col gap-8 items-center"}>
                    <Link to={"/"}><img src={logo} className="w-56 pl-1" alt="logo" /></Link>
                    <Form
                        name="normal_login"
                        className="w-80"
                        initialValues={{ remember: true }}
                        layout={"vertical"}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Form.Item
                            name="mail"
                            label={t("label.mail")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.mail") }) as string,
                                },
                                { type: "email", message: t("notification.error.invalidEmail") as string },
                            ]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"}
                                   placeholder={t("label.mail") as string}
                                   type={"email"}
                                   value={emailInput}
                                   onChange={e => setEmailInput(e.target.value)}
                                   maxLength={40} />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            label={t("label.firstName")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.firstName") }) as string,
                                },
                            ]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"}
                                   placeholder={t("label.firstName") as string}
                                   value={firstNameInput}
                                   onChange={e => setFirstNameInput(e.target.value)}
                                   maxLength={30} />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            label={t("label.lastName")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.lastName") }) as string,
                                },
                            ]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"}
                                   placeholder={t("label.lastName") as string}
                                   value={lastNameInput}
                                   onChange={e => setLastNameInput(e.target.value)}
                                   maxLength={30} />
                        </Form.Item>
                        <Form.Item
                            name="dateOfBirth"
                            label={t("label.dateOfBirth")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.dateOfBirth") }) as string,
                                },
                            ]}
                            className={"mb-4"}
                        >
                            <DatePicker
                                size={"large"} className={"w-full"}
                                format={"DD.MM.YYYY"}
                                onChange={onChangeBirthDate} />
                        </Form.Item>
                        <Form.Item
                            name="street"
                            label={t("label.street")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.street") }) as string,
                                },
                            ]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"}
                                   placeholder={t("label.street") as string}
                                   value={streetInput}
                                   onChange={e => setStreetInput(e.target.value)}
                                   maxLength={40} />
                        </Form.Item>
                        <Form.Item
                            name="zipCode"
                            label={t("label.zipCode")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.zipCode") }) as string,
                                },
                                { min: 5, message: t("notification.error.invalidZipCode") as string },
                            ]}
                            className={"mb-4"}
                        >
                            <Input value={zipCodeInput}
                                   autoFocus size={"large"}
                                   className={"w-full rounded"}
                                   placeholder={t("label.zipCode") as string}
                                   onChange={(e) => {
                                       setZipCodeInput(e.target.value)
                                   }}
                                   onKeyPress={(e) => {
                                       if (!/[0-9]/.test(e.key) || zipCodeInput.length >= 5) {
                                           e.preventDefault()
                                       }
                                   }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="city"
                            label={t("label.city")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.city") }) as string,
                                },
                            ]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"}
                                   placeholder={t("label.city") as string}
                                   value={cityInput}
                                   onChange={e => setCityInput(e.target.value)}
                                   maxLength={40} />
                        </Form.Item>
                        <Form.Item
                            name="paypalMail"
                            label={t("label.paypalMail")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.paypalMail") }) as string,
                                },
                                { type: "email", message: t("notification.error.invalidEmail") as string },
                            ]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"large"} className={"rounded"}
                                   placeholder={t("label.paypalMail") as string}
                                   type={"email"}
                                   value={paypalEmailInput}
                                   onChange={e => setPaypalEmailInput(e.target.value)}
                                   maxLength={30} />
                        </Form.Item>
                        <Form.Item
                            name="telephone"
                            label={t("label.telephone")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.telephone") }) as string,
                                },
                                {
                                    pattern: new RegExp(/^\+[1-9][0-9]{9,14}$/),
                                    message: t("notification.error.invalidPhoneNumber") as string,
                                },
                            ]}
                            className={"mb-4"}
                        >
                            <Input
                                autoFocus
                                size={"large"}
                                className={"rounded"}
                                placeholder={t("label.telephone") as string}
                                value={telephoneInput}
                                onChange={e => {
                                    let inputValue = e.target.value
                                    if (inputValue.match(/^[1-9].*$/)) {
                                        inputValue = "+" + inputValue
                                    }
                                    setTelephoneInput(inputValue)
                                }}
                                maxLength={16}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label={t("label.password")}
                            rules={[
                                {
                                    required: true,
                                    message: t("notification.error.required", { field: t("label.password") }) as string,
                                },
                                { min: 8, message: t("notification.error.passwordLength") as string },
                            ]}
                        >
                            <Input size={"large"} className={"rounded"} placeholder={t("label.password") as string}
                                   type={"password"}
                                   value={passwordInput}
                                   onChange={e => setPasswordInput(e.target.value)}
                                   maxLength={40} />
                        </Form.Item>
                        <Form.Item className={"mb-4"}>
                            <Button
                                type={"primary"}
                                htmlType={"submit"}
                                className={"bg-primary text-white !rounded-3xl w-full"}
                                size={"large"}
                                loading={signUpUser.isLoading}
                                disabled={!emailInput || !firstNameInput || !lastNameInput || !dateOfBirthInput || !passwordInput || !streetInput || !zipCodeInput || !cityInput}
                            >
                                {t("action.signup")}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Link to={"/login"}>
                                <Button
                                    className={"!rounded-3xl w-full"}
                                    size={"large"}
                                >
                                    {t("action.back")}
                                </Button>
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default SignUp