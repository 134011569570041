import React, { FC, useState } from "react"
import { Button, Form, Input } from "antd"
import { useTranslation } from "react-i18next"
import { CheckOutlined, EditOutlined } from "@ant-design/icons"
import { useMutation } from "react-query"
import axios from "axios"
import useNotification from "../../../hooks/useNotification"
import { queryKey } from "../../../utils/consts"
import { queryClient } from "../../../App"

interface Address {
    street: string
    zipCode: string
    city: string
}

const Address: FC<Address> = ({ street, zipCode, city }) => {
    const { t } = useTranslation()

    const [edit, setEdit] = useState<boolean>(false)
    const [streetInput, setStreetInput] = useState<string>(street)
    const [zipCodeInput, setZipCodeInput] = useState<string>(zipCode)
    const [cityInput, setCityInput] = useState<string>(city)

    const notification = useNotification()

    const addAddress = useMutation([queryKey.user],
        (address: Address) => {
            return axios.post("/auth/changeAddress", {
                street: address.street,
                zipCode: address.zipCode,
                city: address.city,
            })
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKey.user])
                setEdit(false)
            },
            onError: () => notification("error", "Failed changing address", "Please provide a valid address in germany."),
        },
    )

    const onFinish = () => {
        if (streetInput.length > 0 && zipCodeInput.length === 5 && cityInput.length > 0) {
            addAddress.mutate({
                street: streetInput,
                zipCode: zipCodeInput,
                city: cityInput,
            })
        }
    }

    return (
        <div className={"flex justify-between"}>
            <div className={"flex"}>
                {edit ?
                    <Form
                        onFinish={onFinish}
                    >
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name="street"
                            rules={[{ required: true, message: "Please input your street!" }]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"small"} className={"rounded"}
                                   placeholder={t("label.street") ?? ""}
                                   defaultValue={street}
                                   value={streetInput}
                                   onPressEnter={onFinish}
                                   onChange={e => setStreetInput(e.target.value)}
                                   maxLength={40}/>
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name="zipCode"
                            rules={[{ required: true, message: "Please input your zip code!" },
                                { min: 5, message: "Please input a valid zip code" }]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"small"} className={"rounded"}
                                   placeholder={t("label.zipCode") ?? ""}
                                   defaultValue={zipCode}
                                   value={zipCodeInput}
                                   onPressEnter={onFinish}
                                   onChange={e => setZipCodeInput(e.target.value)}
                                   onKeyPress={(e) => {
                                       if (!/[0-9]/.test(e.key) || zipCodeInput.length >= 5) {
                                           e.preventDefault()
                                       }
                                   }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: "0px" }}
                            name="city"
                            rules={[{ required: true, message: "Please input your city!" }]}
                            className={"mb-4"}
                        >
                            <Input autoFocus size={"small"} className={"rounded"}
                                   placeholder={t("label.city") ?? ""}
                                   defaultValue={city}
                                   value={cityInput}
                                   onPressEnter={onFinish}
                                   onChange={e => setCityInput(e.target.value)}
                                   maxLength={40}/>
                        </Form.Item>
                    </Form>
                    :
                    <div className={"flex pt-1 pl-2 align-middle"}>
                        {street}<br />
                        {zipCode}<br />
                        {city}
                    </div>
                }
            </div>
            <div>
                {edit ?
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => onFinish()}
                            icon={<CheckOutlined />}
                            title={t("action.submit") ?? ""} />
                    :
                    <Button type={"text"} className="text-grayedOut hover:!text-primary"
                            onClick={() => setEdit(true)}
                            icon={<EditOutlined />}
                            title={t("action.edit") ?? ""} />}
            </div>
        </div>
    )
}

export default Address